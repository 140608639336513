<!-- <div class="table-responsive"> -->
<div class="table-wrapper" style="padding:0px .4rem;">
    <div class='table-container' [class.rtl]="currLang == 'ar'">
        <div class="table-heading" [ngStyle]="toggleTableBorderRadius(false,false,currLang,'heading')">
            <div class="table-meta">
                <div class="table-title">
                    {{tableTitle | translate}}
                </div>
                <div class="total-chip" *ngIf="showTotalNumberFlag">
                    <div class="selected-elements" *ngIf="selection && showSelectionNumberFlag && selectedCount>0">
                        {{selectedCount | arabicNumber:currLang}} {{selectedTitle | translate}}<span
                            style="margin-inline-start: 4px; margin-inline-end: 4px;">/</span>
                    </div>
                    <div class="total-elements">
                        {{data?.length | arabicNumber:currLang}} {{totalTitle |translate}} <span
                            *ngIf="showPagination">{{'table-view.total-titles.per-page' | translate}}</span>
                    </div>
                </div>
            </div>
            <div class="table-actions">
                <!-- <div *ngIf="showExport" class="generat-excel-container">
                        <button class="download-btn" (click)="generateExcelFile(true)" [disabled]="data?.length == 0">
                            <svg *ngIf="data?.length > 0;else disabledExportSvg"
                            width="18" height="18" viewBox="0 -1 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_706_706)">
                                    <path
                                        d="M17.4375 2.81289H10.125V1.12539C10.125 0.957767 10.0508 0.799142 9.92138 0.692267C9.79313 0.585392 9.621 0.539267 9.459 0.573017L0.459 2.26052C0.192375 2.31002 0 2.54177 0 2.81289V15.1879C0 15.4579 0.192375 15.6908 0.459 15.7403L9.459 17.4278C9.49275 17.4345 9.52763 17.4379 9.5625 17.4379C9.693 17.4379 9.82013 17.3929 9.92138 17.3085C10.0508 17.2016 10.125 17.0419 10.125 16.8754V15.1879H17.4375C17.748 15.1879 18 14.9359 18 14.6254V3.37539C18 3.06489 17.748 2.81289 17.4375 2.81289ZM7.7355 10.8803C7.94025 11.1131 7.91663 11.4686 7.68262 11.6734C7.57575 11.7668 7.44412 11.8129 7.3125 11.8129C7.15613 11.8129 7.00088 11.7476 6.8895 11.6205L5.25375 9.75189L3.81937 11.5969C3.708 11.7386 3.5415 11.8129 3.375 11.8129C3.25462 11.8129 3.13313 11.7746 3.02963 11.6948C2.78438 11.5035 2.7405 11.1503 2.93063 10.905L4.49888 8.88902L2.952 7.12052C2.74725 6.88764 2.77087 6.53214 3.00488 6.32739C3.23775 6.12264 3.59213 6.14514 3.79913 6.38027L5.20312 7.98452L6.86925 5.84252C7.0605 5.59839 7.41375 5.55339 7.659 5.74464C7.90425 5.93477 7.94812 6.28802 7.75687 6.53439L5.95688 8.84739L7.7355 10.8803ZM16.875 14.0629H10.125V12.9379H11.8125C12.123 12.9379 12.375 12.6859 12.375 12.3754C12.375 12.0649 12.123 11.8129 11.8125 11.8129H10.125V10.6879H11.8125C12.123 10.6879 12.375 10.4359 12.375 10.1254C12.375 9.81489 12.123 9.56289 11.8125 9.56289H10.125V8.43789H11.8125C12.123 8.43789 12.375 8.18589 12.375 7.87539C12.375 7.56489 12.123 7.31289 11.8125 7.31289H10.125V6.18789H11.8125C12.123 6.18789 12.375 5.93589 12.375 5.62539C12.375 5.31489 12.123 5.06289 11.8125 5.06289H10.125V3.93789H16.875V14.0629Z"
                                        fill="#0074ec" />
                                    <path
                                        d="M15.1875 5.0629H14.0625C13.752 5.0629 13.5 5.3149 13.5 5.6254C13.5 5.9359 13.752 6.1879 14.0625 6.1879H15.1875C15.498 6.1879 15.75 5.9359 15.75 5.6254C15.75 5.3149 15.498 5.0629 15.1875 5.0629Z"
                                        fill="#0074ec" />
                                    <path
                                        d="M15.1875 7.3129H14.0625C13.752 7.3129 13.5 7.5649 13.5 7.8754C13.5 8.1859 13.752 8.4379 14.0625 8.4379H15.1875C15.498 8.4379 15.75 8.1859 15.75 7.8754C15.75 7.5649 15.498 7.3129 15.1875 7.3129Z"
                                        fill="#0074ec" />
                                    <path
                                        d="M15.1875 9.5629H14.0625C13.752 9.5629 13.5 9.8149 13.5 10.1254C13.5 10.4359 13.752 10.6879 14.0625 10.6879H15.1875C15.498 10.6879 15.75 10.4359 15.75 10.1254C15.75 9.8149 15.498 9.5629 15.1875 9.5629Z"
                                        fill="#0074ec" />
                                    <path
                                        d="M15.1875 11.8129H14.0625C13.752 11.8129 13.5 12.0649 13.5 12.3754C13.5 12.6859 13.752 12.9379 14.0625 12.9379H15.1875C15.498 12.9379 15.75 12.6859 15.75 12.3754C15.75 12.0649 15.498 11.8129 15.1875 11.8129Z"
                                        fill="#0074ec" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_706_706">
                                        <rect width="18" height="18" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <ng-template #disabledExportSvg>
                                <svg
                                    width="18" height="18" viewBox="0 -1 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_706_706)">
                                            <path
                                                d="M17.4375 2.81289H10.125V1.12539C10.125 0.957767 10.0508 0.799142 9.92138 0.692267C9.79313 0.585392 9.621 0.539267 9.459 0.573017L0.459 2.26052C0.192375 2.31002 0 2.54177 0 2.81289V15.1879C0 15.4579 0.192375 15.6908 0.459 15.7403L9.459 17.4278C9.49275 17.4345 9.52763 17.4379 9.5625 17.4379C9.693 17.4379 9.82013 17.3929 9.92138 17.3085C10.0508 17.2016 10.125 17.0419 10.125 16.8754V15.1879H17.4375C17.748 15.1879 18 14.9359 18 14.6254V3.37539C18 3.06489 17.748 2.81289 17.4375 2.81289ZM7.7355 10.8803C7.94025 11.1131 7.91663 11.4686 7.68262 11.6734C7.57575 11.7668 7.44412 11.8129 7.3125 11.8129C7.15613 11.8129 7.00088 11.7476 6.8895 11.6205L5.25375 9.75189L3.81937 11.5969C3.708 11.7386 3.5415 11.8129 3.375 11.8129C3.25462 11.8129 3.13313 11.7746 3.02963 11.6948C2.78438 11.5035 2.7405 11.1503 2.93063 10.905L4.49888 8.88902L2.952 7.12052C2.74725 6.88764 2.77087 6.53214 3.00488 6.32739C3.23775 6.12264 3.59213 6.14514 3.79913 6.38027L5.20312 7.98452L6.86925 5.84252C7.0605 5.59839 7.41375 5.55339 7.659 5.74464C7.90425 5.93477 7.94812 6.28802 7.75687 6.53439L5.95688 8.84739L7.7355 10.8803ZM16.875 14.0629H10.125V12.9379H11.8125C12.123 12.9379 12.375 12.6859 12.375 12.3754C12.375 12.0649 12.123 11.8129 11.8125 11.8129H10.125V10.6879H11.8125C12.123 10.6879 12.375 10.4359 12.375 10.1254C12.375 9.81489 12.123 9.56289 11.8125 9.56289H10.125V8.43789H11.8125C12.123 8.43789 12.375 8.18589 12.375 7.87539C12.375 7.56489 12.123 7.31289 11.8125 7.31289H10.125V6.18789H11.8125C12.123 6.18789 12.375 5.93589 12.375 5.62539C12.375 5.31489 12.123 5.06289 11.8125 5.06289H10.125V3.93789H16.875V14.0629Z"
                                                fill="#B4B4B4" />
                                            <path
                                                d="M15.1875 5.0629H14.0625C13.752 5.0629 13.5 5.3149 13.5 5.6254C13.5 5.9359 13.752 6.1879 14.0625 6.1879H15.1875C15.498 6.1879 15.75 5.9359 15.75 5.6254C15.75 5.3149 15.498 5.0629 15.1875 5.0629Z"
                                                fill="#B4B4B4" />
                                            <path
                                                d="M15.1875 7.3129H14.0625C13.752 7.3129 13.5 7.5649 13.5 7.8754C13.5 8.1859 13.752 8.4379 14.0625 8.4379H15.1875C15.498 8.4379 15.75 8.1859 15.75 7.8754C15.75 7.5649 15.498 7.3129 15.1875 7.3129Z"
                                                fill="#B4B4B4" />
                                            <path
                                                d="M15.1875 9.5629H14.0625C13.752 9.5629 13.5 9.8149 13.5 10.1254C13.5 10.4359 13.752 10.6879 14.0625 10.6879H15.1875C15.498 10.6879 15.75 10.4359 15.75 10.1254C15.75 9.8149 15.498 9.5629 15.1875 9.5629Z"
                                                fill="#B4B4B4" />
                                            <path
                                                d="M15.1875 11.8129H14.0625C13.752 11.8129 13.5 12.0649 13.5 12.3754C13.5 12.6859 13.752 12.9379 14.0625 12.9379H15.1875C15.498 12.9379 15.75 12.6859 15.75 12.3754C15.75 12.0649 15.498 11.8129 15.1875 11.8129Z"
                                                fill="#B4B4B4" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_706_706">
                                                <rect width="18" height="18" fill="white" />
                                            </clipPath>
                                        </defs>
                                </svg>
                            </ng-template>
                            <span>{{'generateExcel.generate-file' | translate}}</span>
                        </button>
                    </div> -->
            </div>
        </div>
        <div class="tableFixHead">
            <table class="fixed_header">
                <thead>
                    <tr [class]="currentView == 'compact' ? 'compact-tr' : ''">
                        <th *ngIf="selectionAll" class="check-box--cell" scope="col">
                            <mat-checkbox [checked]="AllRowsIsChecked" (change)="checkBoxSelectAll($event)"
                                *ngIf="!singleSelect"></mat-checkbox>
                        </th>
                        <ng-container *ngFor="let col of columns">
                            <th *ngIf="checkColumn(col.type, view)" [class]="col.type"
                                [ngStyle]="{ 'text-align': currLang == 'en' ? 'left' : 'right' }">
                                {{ col.name }}
                                <ng-container *ngIf="col.sort">
                                    <svg [class]="sortingOrder === 'asc' && sortedCol === col.source
                                        ? 'sorting-icons active'
                                        : 'sorting-icons'" (click)="sort(col, 'asc')"
                                        xmlns="http://www.w3.org/2000/svg" width="6.686" height="11.806"
                                        viewBox="0 0 6.686 11.806">
                                        <path id="Path_5503" data-name="Path 5503"
                                            d="M3.342,11.806a1.372,1.372,0,0,1-.98-.408L.262,9.285A.9.9,0,0,1,.9,7.748H2.881V.461a.461.461,0,0,1,.922,0V7.748a.923.923,0,0,1-.922.922H.951l2.066,2.078a.459.459,0,0,0,.652,0L5.734,8.67H5.279a.461.461,0,1,1,0-.922h.507a.9.9,0,0,1,.636,1.538L4.322,11.4a1.372,1.372,0,0,1-.98.408Zm0,0"
                                            transform="translate(0.001)" fill="#707070" />
                                    </svg>
                                    <!--  -->
                                    <svg (click)="sort(col, 'desc')" [class]="sortingOrder === 'desc' && sortedCol === col.source
                                        ? 'sorting-icons active'
                                        : 'sorting-icons'" xmlns="http://www.w3.org/2000/svg" width="6.686"
                                        height="11.806" viewBox="0 0 6.686 11.806">
                                        <path id="Path_5502" data-name="Path 5502"
                                            d="M225.353,11.806a.461.461,0,0,1-.461-.461V4.058h-1.983a.9.9,0,0,1-.636-1.538l2.1-2.113a1.382,1.382,0,0,1,1.96,0l2.1,2.113a.9.9,0,0,1-.636,1.538h-.507a.461.461,0,1,1,0-.922h.454l-2.066-2.078a.459.459,0,0,0-.652,0l-2.066,2.078h1.93a.923.923,0,0,1,.922.922v7.287A.461.461,0,0,1,225.353,11.806Zm0,0"
                                            transform="translate(-222.01)" fill="#707070" />
                                    </svg>
                                </ng-container>
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <div *ngIf="loading" class="loading">
                        <mat-spinner *ngIf="true" mode="indeterminate"></mat-spinner>
                    </div>
                    <div class="body-container" *ngIf="!loading">
                        <ng-container *ngFor="let row of data; index as rowIndex">
                            <tr [class]="{
                                'compact-tr': view == 'compact',
                                selected: selectedRows[rowIndex]
                            }" (click)="rowClicked(row, rowIndex)">
                                <td class="check-box--cell" *ngIf="selection">
                                    <mat-checkbox [checked]="AllRowsIsChecked || row.isSelected"
                                        (change)="checkboxAction($event, row, rowIndex)"
                                        (click)="$event.stopPropagation()">
                                    </mat-checkbox>
                                </td>
                                <ng-container *ngFor="let col of columns">
                                    <td *ngIf="col.type == 'index'" [class]="col.type" [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }">
                                        <span [id]="'index_' + row.id">
                                            {{ rowIndex + 1 }}
                                        </span>
                                    </td>
                                    <td *ngIf="view == 'list' && col.type == 'image'" [class]="col.type">
                                        <img [id]="'img_' + row.id" class="profile-img"
                                            [src]="getImage(row[col.source])" [ngStyle]="{
                                      'text-align': currLang == 'en' ? 'left' : 'right'
                                    }" />
                                    </td>
                                    <td *ngIf="col.type == 'string'" [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }">
                                        <span [id]="col.source + '_' + row.id"
                                            [ngClass]="{ link: row[col.source + '_link'] }"
                                            (click)="linkClicked(row, col, rowIndex)">{{ row[col.source] }}</span>

                                        <div class="empty-cell" *ngIf="!row[col.source] && row[col.source] !=0">
                                            <div class="filler"></div>
                                        </div>
                                    </td>

                                    <td *ngIf="col.type == 'hover'" [ngStyle]="{
                                  'text-align': currLang == 'en' ? 'left' : 'right'
                                }">
                                        <div class="hover-wrapper" [id]="col.source + '_' + row.id"
                                            [ngClass]="{ link: row[col.source + '_link'] }"
                                            (click)="linkClicked(row, col, rowIndex)">
                                            <div class="hover-element" (mouseenter)="row[col.show_hover_data]=true"
                                                (mouseleave)="row[col.show_hover_data]=false">
                                                {{ row[col.source] }}
                                            </div>
                                            <div class="hovered-data" *ngIf=" row[col.show_hover_data]">
                                                <div class="hovered-title" *ngIf=" row[col.show_hover_data_title]">
                                                    {{row[col.hovered_data_title] | translate}}
                                                </div>
                                                <div class="hovered-content">
                                                    {{ row[col.hovered_data]}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="empty-cell" *ngIf="!row[col.source] && row[col.source] !=0">
                                            <div class="filler"></div>
                                        </div>
                                    </td>

                                    <td *ngIf="col.type == 'date_time'" [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }">
                                        <span [id]="col.source + '_' + row.id">{{
                                            row[col.source] | localDateTime
                                            }}</span>
                                    </td>
                                    <td *ngIf="col.type == 'color'" class="color" [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }">
                                        <div [id]="col.source + '_' + row.id"
                                            [ngStyle]="{ 'background-color': row[col.source] }"></div>
                                    </td>

                                    <td *ngIf="col.type == 'attendanceStatus'" class="attendance-container" [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }">
                                        <span [id]="col.source + '_' + row.id" *ngIf="row[col.source]" [class]="
                                      row[col.source] == 'present'
                                        ? 'label-present '
                                        : 'label-not-present'
                                    ">{{ "table-view." + row[col.source] | translate }}</span>
                                    </td>

                                    <!-- tooltip with multivalues -->
                                    <td *ngIf="col.type === 'multipleValues'" [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }">
                                        <!-- "right-top" -->
                                        <span *ngIf="row[col.source2]?.length == 0" [id]="col.source + '_' + row.id">
                                            {{ row[col.source] }}
                                        </span>
                                        <span [id]="col.source + '_' + row.id" *ngIf="row[col.source2]?.length > 0"
                                            triggers="manual" #t="ngbTooltip"
                                            (mouseover)="t.open(); $event.stopPropagation()"
                                            (mouseleave)="t.close(); $event.stopPropagation()" [placement]="
                                      currLang == 'en' ? 'bottom-left' : 'bottom-right'
                                    " tooltipClass="my-custom-class" [ngbTooltip]="infoTemplate">
                                            {{ row[col.source] }}
                                        </span>
                                        <ng-template #infoTemplate>
                                            <div class="info-container">
                                                <span class="header" *ngIf="row[col.source2]?.length > 0">{{
                                                    "busses.trips" | translate
                                                    }}</span>
                                                <div *ngFor="let item of row[col.source2]">{{ item }}</div>
                                            </div>
                                        </ng-template>
                                    </td>

                                    <td *ngIf="col.type === 'backSlashSeperatedNumbers'" [ngStyle]="{
                                            'text-align': currLang == 'en' ? 'left' : 'right'
                                          }">
                                        {{ row[col.source]?.first_number | arabicNumber:currLang}} / {{
                                        row[col.source]?.second_number | arabicNumber:currLang}}
                                    </td>
                                    <td *ngIf="col.type === 'translateNumbers'" [ngStyle]="{
                                            'text-align': currLang == 'en' ? 'left' : 'right'
                                        }">
                                        {{ (row[col.source] ? row[col.source] : 0) | arabicNumber:currLang}}
                                    </td>

                                    <td *ngIf="col.type == 'report-way'" [ngStyle]="{
                                        'text-align': currLang == 'en' ? 'left' : 'right'
                                      }">
                                        <span [id]="col.source + '_' + row.id" *ngIf="row[col.source]">
                                            <span *ngIf="row[col.source] == 'to_school'">
                                                {{"table-view.reports.way-morning"| translate }}
                                            </span>
                                            <span *ngIf="row[col.source] == 'to_home'">
                                                {{"table-view.reports.way-afternoon"| translate }}
                                            </span>
                                    </span>
                                    </td>

                                    <td *ngIf="col.type == 'report-time'" [ngStyle]="{
                                        'text-align': currLang == 'en' ? 'left' : 'right'
                                    }">
                                    {{ (row[col.source] ? row[col.source] : 0) | arabicNumber:currLang }}
                                    <span>
                                            {{"table-view.reports.time-prefix"| translate }}
                                    </span>
                                    </td>

                                    <td *ngIf="col.type == 'report-date'" [ngStyle]="{
                                        'text-align': currLang == 'en' ? 'left' : 'right'
                                    }">
                                    <span>
                                            {{localizeDate(row[col.source])}}
                                    </span>
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </div>
                </tbody>
                <div *ngIf="noResultsFlag && !loading" class="centered text-muted">
                    <strong>{{
                        currLang === "en" ? "No Results Found" : "لا توجد بيانات"
                        }}</strong>
                </div>
            </table>
        </div>
        <div class="table-footer" *ngIf="pagination" [ngStyle]="toggleTableBorderRadius(false,false,currLang,'footer')">
            <button class="nav-btn prev-btn" (click)="paginationChange(-1)"
                [disabled]="pagination?.from == 1 || data?.length == 0">
                <div class="icon">
                    <svg [ngStyle]="{'transform': currLang == 'en' ? 'rotate(0)' : 'rotate(180deg)' , 'stroke': pagination?.from === 1 || data?.length == 0 ? '#B7B7B7' : '#344054'}"
                        xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699"
                            stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div class="label">
                    {{'table-view.previous' |translate}}
                </div>
            </button>
            <div class="paganation-text">
                {{pagination?.from | arabicNumber:currLang}} {{'table-view.to' |translate}} {{pagination?.to |
                arabicNumber:currLang}} {{'table-view.of' | translate}} {{pagination?.total | arabicNumber:currLang}}
            </div>
            <button class="nav-btn next-btn" (click)="paginationChange(1)"
                [disabled]="pagination?.to == pagination?.total || data?.length == 0">
                <div class="label">
                    {{'table-view.next' |translate}}
                </div>
                <div class="icon">
                    <svg [ngStyle]="{'transform': currLang == 'en' ? 'rotate(0)' : 'rotate(180deg)', 'stroke': pagination?.to == pagination?.total || data?.length == 0 ? '#B7B7B7' : '#344054'}"
                        xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path
                            d="M4.1665 10.0003H15.8332M15.8332 10.0003L9.99984 4.16699M15.8332 10.0003L9.99984 15.8337"
                            stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </button>
        </div>

    </div>
</div>