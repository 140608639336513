import { Component } from '@angular/core';
import { ReportsControlBarComponent } from '../reports-control-bar/reports-control-bar.component';
import { BehaviorSubject, combineLatest, filter, skip, Subject, takeUntil } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import { ErrorMsgService } from '../../../../shared/services/error-msg.service';
import moment from 'moment';
import { TableViewComponent } from '../../../../shared/components/table-view/table-view.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../../shared/services/global.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-journey-time',
  standalone: true,
  imports: [CommonModule, RouterModule,ReportsControlBarComponent, TableViewComponent, TranslateModule],
  templateUrl: './journey-time.component.html',
  styleUrl: './journey-time.component.scss'
})
export class JourneyTimeComponent {
  company_id: any
  private isInitialized = false;
  private destroy$ = new Subject<void>();  // Create a subject for takeUntil
  private periodFilter$ = new BehaviorSubject<string | null>(null);
  private dateChanged$ = new BehaviorSubject<Date | null>(null);
  private searchChanged$ = new BehaviorSubject<string | null>(null);
  private pageNumberChanged$ = new BehaviorSubject<number>(1);

  dataArray: any[] = [];
  tableControls: any = { data: [] };
  pageNumber: number = 1;
  itemsPerPage: number = 25;
  is_paginated: number = 1;
  currLang: string = 'en';
  loading!: boolean;
  // listType!: string;
  rowsView: string = 'list';
  from!: number;
  to!: number;
  total!: number;
  // period picker options
  periodPickerOptions: object[] = [
    { name: 'new-dashboard.reports.control-bar.period-filter.morning', value: 'to_school' },
    { name: 'new-dashboard.reports.control-bar.period-filter.afternoon', value: 'to_home' }
  ]

  constructor(private dashboarService: DashboardService,
    private errorMsgService: ErrorMsgService,
    private translate: TranslateService,
    private globalService: GlobalService,

  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
      this.setTableData(this.dataArray);
    });
  }


  ngOnInit() {
    this.isInitialized = true;
    this.company_id = JSON.parse(localStorage.getItem('userData') || '{}')?.value?.company_id ?? null;
    this.getFilters();
  }

  getFilters() {
    // Combine the latest values from all filters
    combineLatest([this.periodFilter$, this.dateChanged$, this.searchChanged$, this.pageNumberChanged$])
      .pipe(
        skip(this.isInitialized ? 1 : 0),  // Skip the first emission during initialization
        // Ensure all filters have valid values
        filter(([period, date, search, page]) => !!period && !!date),
        takeUntil(this.destroy$)
      )
      .subscribe(([period, date, search, page]) => {
        this.getJourenyTimes(period, date, search, page);
      });
  }

  periodFilterChanged(period: string) {
    this.periodFilter$.next(period);
  }

  dateChanged(date: any) {
    this.dateChanged$.next(date);
  }

  searchChanged(search: string) {
    this.searchChanged$.next(search);
  }

  getJourenyTimes(period: any, date: any, search: any, page: any) {
    this.loading = true;
    let dateHMS = this.attachCurrentTimeToSelectedDate(date);
    let formatedDate = moment.utc(dateHMS).locale('en').format('YYYY-MM-DD');
    // let formatedDate = moment(date).locale('en').format('YYYY-MM-DD');
    this.dashboarService.getJourneyTimesReport(this.company_id, formatedDate, period, search, this.itemsPerPage, this.is_paginated, page).subscribe((res: any) => {
      this.dataArray = res?.data?.trips?.data.map((trip: any) => {
        return {
          name: trip?.name,
          way: trip?.way,
          date: trip?.date,
          time: trip?.journey_time,
          bus_name: trip?.bus_name,
          driver_name: trip?.driver_name,
        };
      });
      this.from = res?.data?.trips?.from;
      this.to = res?.data?.trips?.to;
      this.total = res?.data?.trips?.total;
      this.setTableData(this.dataArray);
      this.loading = false;
    },(err =>{
      this.errorMsgService.setMessage(err.error.message);
    }))
  }

  setTableData(data: any[]) {
    this.tableControls = {
      selection: false,
      paging: true,
      view: this.rowsView,
      title: 'table-view.titles.reports-trip',
      totalTitle: 'table-view.total-titles.reports-trip',
      pagination: {
        from: this.from,
        to: this.to,
        total: this.total,
      },
      columns: [
        {
          name: this.translate.instant('new-dashboard.reports.journey-times.table.headers.name'),
          sort: false,
          type: 'string',
          source: 'name',
        },
        {
          name: this.translate.instant('new-dashboard.reports.journey-times.table.headers.way'),
          sort: false,
          type: 'report-way',
          source: 'way',
        },

        {
          name: this.translate.instant('new-dashboard.reports.journey-times.table.headers.date'),
          sort: false,
          type: 'report-date',
          source: 'date',
        },
        {
          name: this.translate.instant('new-dashboard.reports.journey-times.table.headers.journey-time'),
          sort: false,
          type: 'report-time',
          source: 'time',
        },
        {
          name: this.translate.instant('new-dashboard.reports.journey-times.table.headers.bus-name'),
          sort: false,
          type: 'string',
          source: 'bus_name',
        },
        {
          name: this.translate.instant('new-dashboard.reports.journey-times.table.headers.driver-name'),
          sort: false,
          type: 'string',
          source: 'driver_name',
        },
      ],
      data: data,
    };
  }
  tableEventHandeler(event: any) {
    switch (event.action) {
      case 'pagination':
        this.paginationHandling(event.target);
        break;
    }
  }

  paginationHandling(event: any) {
    this.pageNumber += event.dir;
    this.pageNumberChanged$.next(this.pageNumber);
  }

  attachCurrentTimeToSelectedDate(selectedDate:any){
    let currentTime = new Date()
    let dateTime = new Date(selectedDate.getTime());
    dateTime.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());
    return dateTime
  }

  
  ngOnDestroy() {
    this.destroy$.next();  // Emit to trigger the unsubscription
    this.destroy$.complete();  // Complete the subject
  }

}
