<div class="list" [ngStyle]="{ direction: currLang == 'en' ? 'ltr' : 'rtl' }">
    <div class="header">
        <div class="title">
            <span *ngIf="listType == 'trip-reports'">{{'reports.trip-reports.title' | translate}}</span>
            <span *ngIf="listType == 'trip-details' || listType == 'trip-user-history'">
                <img (click)="eventHandler('back')" src="../../../../assets/images/svg/back.svg" alt=""
                    style="cursor: pointer;" [ngStyle]="{ transform: currLang == 'en' ? '' : 'rotate(180deg)' }" />
                    {{title}}
                <span *ngIf="listType == 'trip-user-history'">{{'reports.trip-history.reports' |translate}}</span>
            </span>
        </div>
        <div class="actions">
            <!-- <img class="icon" (click)="eventHandler('print')" style="cursor: pointer;" src="../../../../assets/images/svg/print.svg" /> -->
            <img (click)="eventHandler('export')" style="cursor: pointer;"
                src="../../../../assets/images/svg/export.svg" />
        </div>
    </div>
</div>