import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: StorageService
  ) {}
  login(body: any) {
    return this.http.post<any>(environment.url + 'backoffice/login', body);
  }
  isLoggedIn(): boolean {
    if (localStorage.getItem('token')) {
      return true;
    }
    return false;
  }
  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }
  hasAccess(path: string) {
    // let user = JSON.parse(this.storage.userData);
    // if (user == null ) {
    //   this.logout();
    //   return false;
    // } else {
    // }
  }
}
