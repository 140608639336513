import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { GlobalService } from '../../../../shared/services/global.service';
import moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { SnackBarErrorComponent } from '../../../../shared/components/snack-bar-error/snack-bar-error/snack-bar-error.component';

@Component({
  selector: 'app-reports-control-bar',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, CalendarModule],
  templateUrl: './reports-control-bar.component.html',
  styleUrl: './reports-control-bar.component.scss',
  animations: [
    trigger('rotateAnimation', [
      state('0', style({ transform: 'rotate(0)' })),
      state('180', style({ transform: 'rotate(180deg)' })),
      transition('0 <=> 180', animate('300ms ease-in-out')),
    ]),
    trigger('rotateAttendancAnimation', [
      state('0', style({ transform: 'rotate(0)' })),
      state('180', style({ transform: 'rotate(180deg)' })),
      transition('0 <=> 180', animate('300ms ease-in-out')),
    ]),
  ],
})
export class ReportsControlBarComponent {
  // search 
  searchInput: string = ''
  @Output() searchChanged = new EventEmitter<any>();
  entityEn: string = '';    // For English
  entityAr: string = '';  // For Arabi

  // period filter 
  @Input() periodFilterOptions: any[] = [];
  @Input() view: any;
  @Input() resetFilter: any = false;
  @Output() optionSelected = new EventEmitter<any>();
  selectedPeriodOption: any;
  isPeriodDropdownOpen = false;
  // attendance filter
  isAttendanceDropdownOpen = false;
  selectedAttendanceOption: any;
  @Output() attendanceOptionSelected = new EventEmitter<any>();
  attendanceFilterOptions:any = [];

  // date filter
  mainDate: any;
  formatedMainDate: any;
  todaysDate: any;
  dateFormat: string = "dd/mm/yy";
  @Output() dateChanged = new EventEmitter<any>();

  // genral 
  currLang: any;
  timer: any;

  constructor(private container: ElementRef,
    private cdr: ChangeDetectorRef,
    private globalService: GlobalService,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private snackBar: SnackBarService,
  ) {
    this.globalService.getLang().subscribe(lng => {
      this.currLang = lng;
    });
    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
    });
    this.initializeAttendanceFilterOptions();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.container.nativeElement.contains(event.target)) {
      this.resetOpenedFilters();
    }
  }


  searchHandler() {
    const regex = '^[\a-zA-z0-9-_\u0621-\u064A\u0660-\u0669 ]+$';
    let foundError = this.searchInput.match(regex);
    if (this.searchInput === '' || foundError !== null) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.searchChanged.emit(this.searchInput)
      }, 1400)
    }
    else {
      this.snackBar.setSnackBarMessage(this.translate.instant('reports-controlbar.special-char'));
      this._snackBar.openFromComponent(SnackBarErrorComponent, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  ngOnInit() {
    this.globalService.getLang().subscribe((lng: any) => {
      this.currLang = lng;
    });
    this.initSearch();
    this.initDates();
    this.initPeriodFilter();
    this.initAttendanceFilter();
    this.updateSearchEntityForView();
  }

  initSearch() {
    this.searchChanged.emit(this.searchInput)
  }

  initPeriodFilter() {
    if (this.view != 'staff-on-duty' && this.view != 'driver-on-duty') {
      // Select the first value by default
      let preSelectedFilterValue = this.selectValueBaseOnView(this.view)
      if (preSelectedFilterValue && this.periodFilterOptions.length > 0) {
        this.preSelectFilterValue(preSelectedFilterValue);
      }
      else {
        if (this.periodFilterOptions.length > 0) {
          this.selectedPeriodOption = this.periodFilterOptions[0];
        }
      }
      this.optionSelected.emit(this.selectedPeriodOption.value);
    }
  }


  toggleDropdown(fitlterType: string) {
    if (fitlterType == 'period') {
      this.isPeriodDropdownOpen = !this.isPeriodDropdownOpen;
      this.isAttendanceDropdownOpen = false;
    }
    else if (fitlterType == 'attendance') {
      this.isAttendanceDropdownOpen = !this.isAttendanceDropdownOpen;
      this.isPeriodDropdownOpen = false;
    }
  }

  selectOption(option: any, filterType: string) {
    switch (filterType) {
      case 'period':
        this.selectedPeriodOption = option;
        this.optionSelected.emit(option.value);
        this.isPeriodDropdownOpen = false;
        break;

      case 'attendance':
        this.selectedAttendanceOption = option;
        this.attendanceOptionSelected.emit(option.value);
        this.isAttendanceDropdownOpen = false;
        break;

      default:
        break;
    }

    this.cdr.detectChanges()
  }

  selectValueBaseOnView(view: string) {
    let selectedValue!: any;
    switch (view) {
      case 'journey-times':
        selectedValue = localStorage.getItem('journeyPeriodValue')
        break;
      case 'bus-attendance':
        selectedValue = localStorage.getItem('attendancePeriodValue')
        break;
      case 'daily-delay':
        selectedValue = localStorage.getItem('delayPeriodValue')
        break;
      default:
        break;
    }
    return selectedValue
  }

  preSelectFilterValue(preSelectedValue: string) {
    switch (preSelectedValue) {
      case 'morning':
        this.selectedPeriodOption = this.periodFilterOptions[0];
        break;
      case 'afternoon':
        this.selectedPeriodOption = this.periodFilterOptions[1];
        break;
      default:
        break;
    }
  }

  initAttendanceFilter() {
    if (this.view == 'bus-attendance') {
      this.selectedAttendanceOption = this.attendanceFilterOptions[0];
      this.attendanceOptionSelected.emit(this.selectedAttendanceOption.value);
    }
  }

  initializeAttendanceFilterOptions(){
    this.attendanceFilterOptions = [
      { name: 'new-dashboard.reports.control-bar.attendance-filter.all', value: '' },
      { name: 'new-dashboard.reports.control-bar.attendance-filter.present', value: 'present' },
      { name: 'new-dashboard.reports.control-bar.attendance-filter.absent', value: 'absent' },
      { name: 'new-dashboard.reports.control-bar.attendance-filter.missed', value: 'missed' }
    ]
  }

  initDates() {
    this.mainDate = new Date(localStorage.getItem('dashboardMainDate') as string);
    this.dateChanged.emit(this.mainDate);
    this.todaysDate = new Date();
    // this.formatedMainDate = moment.utc(this.mainDate).locale('en').format('YYYY-MM-DD');
  }

  setDateFilter(event: any) {
    this.mainDate = event;
    this.dateChanged.emit(this.mainDate);
  }

  resetOpenedFilters() {
    this.isAttendanceDropdownOpen = false;
    this.isPeriodDropdownOpen = false;
  }

   updateSearchEntityForView() {
    console.log(this.view);
  switch (this.view) {
    case 'journey-times':
      this.entityEn = 'trip name';
      this.entityAr = 'أسم الرحلة';
      break;

      case 'bus-attendance':
        this.entityEn = 'student or trip name';
        this.entityAr = 'أسم الطالب أو أسم الرحلة';
      break;

      case 'daily-delay':
        this.entityEn = 'trip name';
        this.entityAr = 'أسم الرحلة';
      break;

      case 'staff-on-duty':
        this.entityEn = 'name or phone number';
        this.entityAr = 'الأسم أو رقم الهاتف';
      break;

      case 'driver-on-duty':
        this.entityEn = 'name or phone number';
        this.entityAr = 'الأسم أو رقم الهاتف';
      break;
  
    default:
      break;
  }
  }
}
