import { Routes } from '@angular/router';
import { provideRouter } from '@angular/router';
import { LoginComponent } from './core/authentication/login/login.component';
import { LogoutComponent } from './core/authentication/logout/logout.component';
import { LayoutComponent } from './core/layout/layout.component';
import { AuthService } from './core/authentication/services/auth.service';
import { ManagementComponent } from './core/feature_components/management/management.component';
import { AreasComponent } from './core/feature_components/management/areas/areas.component';
import { BusesComponent } from './core/feature_components/management/buses/buses.component';
import { DriversComponent } from './core/feature_components/management/drivers/drivers.component';
import { SupervisorsComponent } from './core/feature_components/management/supervisors/supervisors.component';
import { SubscribersComponent } from './core/feature_components/management/subscribers/subscribers.component';
import { NotFoundComponent } from './core/shared/components/not-found/not-found.component';
import { TripComponent } from './core/feature_components/management/trip/trip.component';
import { DashboardComponent } from './core/feature_components/dashboard/dashboard.component';
import { BusTrackingComponent } from './core/feature_components/dashboard/bus-tracking/bus-tracking.component';
import { TripReportsComponent } from './core/feature_components/dashboard/reports/trip-reports/trip-reports.component';
import { TripDetailsComponent } from './core/feature_components/dashboard/reports/trip-details/trip-details.component';
import { UserTripHistoryComponent } from './core/feature_components/dashboard/reports/user-trip-history/user-trip-history.component';
import { DashboardViewComponent } from './core/feature_components/dashboard/dashboard-view/dashboard-view.component';
import { DailyDelayComponent } from './core/feature_components/dashboard/new-reports/daily-delay/daily-delay.component';
import { BusAttendanceComponent } from './core/feature_components/dashboard/new-reports/bus-attendance/bus-attendance.component';
import { JourneyTimeComponent } from './core/feature_components/dashboard/new-reports/journey-time/journey-time.component';
import { DriverOnDutyComponent } from './core/feature_components/dashboard/new-reports/driver-on-duty/driver-on-duty.component';
import { SuperOnDutyComponent } from './core/feature_components/dashboard/new-reports/super-on-duty/super-on-duty.component';


export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'layout/management', pathMatch: 'full' }, // Redirect root to layout/management
      {
        path: 'layout/dashboard', component: DashboardComponent,
        children: [
          {path: 'dashboard-view', component: DashboardViewComponent},
          { path: 'trip-details/:id', component: TripDetailsComponent },
          { path: 'user-history/:id', component: UserTripHistoryComponent },
          { path: 'bus-tracking', component: BusTrackingComponent },
          {path: 'daily-delay-report', component: DailyDelayComponent},
          {path: 'bus-attendance-report', component: BusAttendanceComponent},
          {path: 'journey-time-report', component: JourneyTimeComponent},
          {path: 'driver-duty-report', component: DriverOnDutyComponent},
          {path: 'supervisor-duty-report', component: SuperOnDutyComponent},

          { path: '', redirectTo: 'dashboard-view', pathMatch: 'full' }
        ]
      },
      {
        path: 'layout/management', component: ManagementComponent,
        children: [
          { path: 'trip', component: TripComponent },
          { path: 'areas', component: AreasComponent },
          { path: 'buses', component: BusesComponent },
          { path: 'drivers', component: DriversComponent },
          { path: 'supervisors', component: SupervisorsComponent },
          { path: 'subscribers', component: SubscribersComponent },
          { path: '', redirectTo: 'buses', pathMatch: 'full' }
        ]
      }
    ]
  },
  { path: '**', component: NotFoundComponent }
];
