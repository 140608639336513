import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../../shared/services/global.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-reports-header',
  standalone: true,
  imports: [CommonModule , TranslateModule],
  templateUrl: './reports-header.component.html',
  styleUrl: './reports-header.component.scss'
})
export class ReportsHeaderComponent {
  currLang: string = 'en';
  @Input('listType') listType!: string;
  @Input('title') title!: any;
  @Output() onEventClicked: EventEmitter<any> = new EventEmitter();
  constructor(
    private translate: TranslateService,
    private globalService: GlobalService,
  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
    });
  }

  ngOnInit(): void {
  }

  eventHandler(type: string) {
    this.onEventClicked.emit({
      action: type,
    });
  }

}
