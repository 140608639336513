<div [class.rtl]="currLang == 'ar'" class="modal" tabindex="-1" role="dialog" id="addTripModal"
    style="direction: ltr; background-color: #00000080;" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div #modal class="modal-content" [ngStyle]="{ direction: currLang == 'en' ? 'ltr' : 'rtl' }">
            <div class="modal-header">
                <h5 class="modal-title">
                    <span *ngIf="modalTitlFlag =='view'">
                        {{ "trip.add-trip-modal.view-trip" | translate }}
                    </span>
                    <span *ngIf="modalTitlFlag =='add'">
                        {{ "trip.add-trip-modal.add-trip" | translate }}
                    </span>
                    <span *ngIf="modalTitlFlag =='edit'">
                        {{ "trip.add-trip-modal.edit-trip" | translate }}
                    </span>
                    <span *ngIf="modalTitlFlag =='dublicate'">
                        {{ "trip.add-trip-modal.dublicate-trip" | translate }}
                    </span>
                </h5>
                <div class="header-btns">
                    <button *ngIf="formFlag == 'view'" class="btn btn-primary edit-btn" (click)="dublicateTrip()">
                        {{ "trip.add-trip-modal.dublicate" | translate }}
                    </button>
                    <button *ngIf="formFlag == 'view'" class="btn btn-primary edit-btn" (click)="editTrip()">
                        {{ "trip.add-trip-modal.edit" | translate }}
                    </button>
                    <svg (click)="closeModalHandler()" xmlns="http://www.w3.org/2000/svg" width="14" height="16"
                        viewBox="0 0 14 16" fill="none">
                        <path d="M6.94989 8L12.8998 15" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                        <path d="M6.94983 8L0.999934 15" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                        <path d="M6.94989 8L12.8998 1" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                        <path d="M6.94983 8L0.999934 1" stroke="#707070" stroke-width="2" stroke-linecap="round" />
                    </svg>
                </div>
            </div>
            <div class="tabs" [attr.dir]="currLang == 'en' ? 'ltr' : 'rtl'">
                <div class="tab" [class.active]="activeTab == 'info'" (click)="activeTab = 'info'">
                    {{ "trip.add-trip-modal.trip-info1" | translate }}
                </div>
                <div class="tab" [class.active]="
          activeTab == 'passengers' || activeTab == 'add-student'
        " [class.disabled]="!fieldsReadOnly" (click)="nextBtn()">
                    {{ "trip.add-trip-modal.trip-info2" | translate }}
                </div>
            </div>
            <div class="modal-body">
                <!-- bus departure point -->
                <div class="bus-departure-wrapper"
                    *ngIf="tripData.way != 'to_home' && activeTab == 'passengers' && selectedStudents.length > 0">
                    <span class="title">
                        {{ "trip.add-trip-modal.bus-departure" | translate }}
                    </span>
                    <div class="departure-location-wrapper">
                        <input type="text" [(ngModel)]="initialBusLocation">
                        <div *ngIf="!initialBusLocation" class="no-initial-location">
                            {{ "trip.add-trip-modal.bus-departure-warn" | translate }}
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="activeTab == 'info'">
                    <form [ngStyle]="{ 'text-align': currLang == 'en' ? 'left' : 'right' }" [formGroup]="formTrip">
                        <div class="row">
                            <!-- basic info section -->
                            <div class="section-title">
                                {{"trip.add-trip-modal.basic-info" | translate}}
                            </div>
                            <div class="col-lg-6">
                                <mat-form-field class="w-100" appearance="outline" dir="ltr">
                                    <mat-label>{{
                                        "trip.add-trip-modal.name" | translate
                                        }}</mat-label>
                                    <input matInput formControlName="name" [readonly]="fieldsReadOnly" />
                                    <mat-error>{{ getValidationMessage("name") }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6">
                                <mat-form-field class="w-100" appearance="outline" dir="ltr">
                                    <mat-label>{{
                                        "trip.add-trip-modal.bus_id" | translate
                                        }}</mat-label>
                                    <mat-select formControlName="bus_id" [disabled]="fieldsReadOnly">
                                        <mat-option [value]="bus.id" *ngFor="let bus of busesList">{{ bus.name
                                            }}</mat-option>
                                    </mat-select>
                                    <mat-error>{{ getValidationMessage("bus_id") }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6">
                                <mat-form-field class="w-100" appearance="outline" dir="ltr">
                                    <mat-label>{{
                                        "trip.add-trip-modal.driver_id" | translate
                                        }}</mat-label>
                                    <mat-select formControlName="driver_id" [disabled]="fieldsReadOnly">
                                        <mat-option [value]="driver.id" *ngFor="let driver of driversList">{{
                                            driver.name }}</mat-option>
                                    </mat-select>
                                    <mat-error>{{ getValidationMessage("driver_id") }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6">
                                <mat-form-field class="w-100" appearance="outline" dir="ltr">
                                    <mat-label>{{
                                        "trip.add-trip-modal.supervisor_id" | translate
                                        }}</mat-label>
                                    <mat-select formControlName="supervisor_id" [disabled]="fieldsReadOnly">
                                        <mat-option [value]="supervisor.id"
                                            *ngFor="let supervisor of supervisorsList">{{ supervisor.name }}
                                        </mat-option>
                                    </mat-select>
                                    <!-- <mat-error>{{
                    getValidationMessage("supervisor_id")
                  }}</mat-error> -->
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6">
                                <mat-form-field class="w-100" appearance="outline" dir="ltr">
                                    <mat-label>{{
                                        "trip.add-trip-modal.area_id" | translate
                                        }}</mat-label>
                                    <mat-select formControlName="area_id" [disabled]="fieldsReadOnly">
                                        <mat-option [value]="area.id" *ngFor="let area of areasList">{{ area.name
                                            }}</mat-option>
                                    </mat-select>
                                    <mat-error>{{ getValidationMessage("area_id") }}</mat-error>
                                </mat-form-field>
                            </div>
                            <!-- trip type section -->
                            <div class="section-title">
                                {{"trip.add-trip-modal.way" | translate}}
                            </div>
                            <div class="col-lg-12 radio" [class.disabled]="fieldsReadOnly">
                                <input class="radio" type="radio" name="way" id="school" value="to_school"
                                    formControlName="way" (change)="wayChanged()" />
                                <label class="text" for="school">{{
                                    "trip.add-trip-modal.to_school" | translate
                                    }}</label>
                                <input class="radio" type="radio" name="way" id="home" value="to_home"
                                    formControlName="way" (change)="wayChanged()" />
                                <label class="text" for="home">{{
                                    "trip.add-trip-modal.to_home" | translate
                                    }}</label>
                                <mat-error>{{ getValidationMessage("way") }}</mat-error>
                            </div>
                            <div class="section-title">
                                {{"trip.add-trip-modal.trip-times" | translate}}
                            </div>
                            <div class="col-lg-6">
                                <mat-form-field class="w-100" appearance="outline" dir="ltr">
                                    <mat-label>{{
                                        "trip.add-trip-modal.start_time" | translate
                                        }}</mat-label>
                                    <input type="time" matInput formControlName="start_time"
                                        [readonly]="fieldsReadOnly" />
                                    <mat-error>{{
                                        getValidationMessage("start_time")
                                        }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6">
                                <mat-form-field class="w-100" appearance="outline" dir="ltr">
                                    <mat-label>{{
                                        "trip.add-trip-modal.end_time" | translate
                                        }}</mat-label>
                                    <input type="time" matInput formControlName="end_time"
                                        [readonly]="fieldsReadOnly" />
                                    <mat-error>{{ getValidationMessage("end_time") }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6">
                                <mat-form-field class="w-100" appearance="outline" dir="ltr">
                                    <mat-label>{{
                                        "trip.add-trip-modal.waiting_time" | translate
                                        }}</mat-label>
                                    <input matInput type="number" formControlName="waiting_time"
                                        [readonly]="fieldsReadOnly" />
                                    <mat-error>{{
                                        getValidationMessage("waiting_time")
                                        }}</mat-error>
                                </mat-form-field>
                            </div>
                            <!-- weekdays section -->
                            <div class="section-title">
                                {{"trip.add-trip-modal.days-title" | translate}}
                            </div>
                            <div class="col-lg-12 days">
                                <div class="day" [class.active]="weekDays[0].isSelected" (click)="daySelected(0)"
                                    [class.disabled]="fieldsReadOnly">
                                    {{ "trip.add-trip-modal.saturday" | translate }}
                                </div>
                                <div class="day" [class.active]="weekDays[1].isSelected" (click)="daySelected(1)"
                                    [class.disabled]="fieldsReadOnly">
                                    {{ "trip.add-trip-modal.sunday" | translate }}
                                </div>
                                <div class="day" [class.active]="weekDays[2].isSelected" (click)="daySelected(2)"
                                    [class.disabled]="fieldsReadOnly">
                                    {{ "trip.add-trip-modal.monday" | translate }}
                                </div>
                                <div class="day" [class.active]="weekDays[3].isSelected" (click)="daySelected(3)"
                                    [class.disabled]="fieldsReadOnly">
                                    {{ "trip.add-trip-modal.tuesday" | translate }}
                                </div>
                                <div class="day" [class.active]="weekDays[4].isSelected" (click)="daySelected(4)"
                                    [class.disabled]="fieldsReadOnly">
                                    {{ "trip.add-trip-modal.wednesday" | translate }}
                                </div>
                                <div class="day" [class.active]="weekDays[5].isSelected" (click)="daySelected(5)"
                                    [class.disabled]="fieldsReadOnly">
                                    {{ "trip.add-trip-modal.thursday" | translate }}
                                </div>
                                <div class="day" [class.active]="weekDays[6].isSelected" (click)="daySelected(6)"
                                    [class.disabled]="fieldsReadOnly">
                                    {{ "trip.add-trip-modal.friday" | translate }}
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-container>
                <ng-container *ngIf="activeTab == 'add-student'">
                    <div class="list" [ngStyle]="{ direction: currLang == 'en' ? 'ltr' : 'rtl' }">
                        <div class="row">
                            <div class="col-8">
                                <div class="control-tab">
                                    <div class="d-flex w-100">
                                        <div class="search">
                                            <input (keyup)="onKeyup($event)" [value]="searchText" class="search-input"
                                                type="text" autocomplete="off"
                                                placeholder="{{ 'control-bar.search' | translate }}" />
                                        </div>
                                        <div class="show-style-control style-control">
                                            <div matTooltip="{{
                          'control-bar.compact-view' | translate
                        }}">
                                                <svg (click)="setViewType('list')" id="normal-svg"
                                                    [attr.class]="currentView == 'list' ? 'active' : ' '"
                                                    xmlns="http://www.w3.org/2000/svg" width="21.474" height="17"
                                                    viewBox="0 0 21.474 17">
                                                    <g id="list_1_" data-name="list (1)"
                                                        transform="translate(0 -53.333)">
                                                        <g id="Group_5111" data-name="Group 5111"
                                                            transform="translate(5.82 55.175)">
                                                            <g id="Group_5110" data-name="Group 5110">
                                                                <path id="Path_5426" data-name="Path 5426"
                                                                    d="M164.527,96H149.793a.46.46,0,1,0,0,.921h14.733a.46.46,0,1,0,0-.921Z"
                                                                    transform="translate(-149.333 -96)"
                                                                    fill="#707070" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_5113" data-name="Group 5113"
                                                            transform="translate(5.82 61.373)">
                                                            <g id="Group_5112" data-name="Group 5112">
                                                                <path id="Path_5427" data-name="Path 5427"
                                                                    d="M164.527,245.333H149.793a.46.46,0,1,0,0,.921h14.733a.46.46,0,1,0,0-.921Z"
                                                                    transform="translate(-149.333 -245.333)"
                                                                    fill="#707070" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_5115" data-name="Group 5115"
                                                            transform="translate(5.82 67.571)">
                                                            <g id="Group_5114" data-name="Group 5114">
                                                                <path id="Path_5428" data-name="Path 5428"
                                                                    d="M164.527,394.667H149.793a.46.46,0,1,0,0,.921h14.733a.46.46,0,1,0,0-.921Z"
                                                                    transform="translate(-149.333 -394.667)"
                                                                    fill="#707070" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_5117" data-name="Group 5117"
                                                            transform="translate(0 65.729)">
                                                            <g id="Group_5116" data-name="Group 5116">
                                                                <path id="Path_5429" data-name="Path 5429"
                                                                    d="M2.3,352a2.3,2.3,0,1,0,2.3,2.3A2.3,2.3,0,0,0,2.3,352Zm0,3.683A1.381,1.381,0,1,1,3.683,354.3,1.383,1.383,0,0,1,2.3,355.683Z"
                                                                    transform="translate(0 -352)" fill="#707070" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_5119" data-name="Group 5119"
                                                            transform="translate(0 59.531)">
                                                            <g id="Group_5118" data-name="Group 5118">
                                                                <path id="Path_5430" data-name="Path 5430"
                                                                    d="M2.3,202.667a2.3,2.3,0,1,0,2.3,2.3A2.3,2.3,0,0,0,2.3,202.667Zm0,3.683a1.381,1.381,0,1,1,1.381-1.381A1.383,1.383,0,0,1,2.3,206.35Z"
                                                                    transform="translate(0 -202.667)" fill="#707070" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_5121" data-name="Group 5121"
                                                            transform="translate(0 53.333)">
                                                            <g id="Group_5120" data-name="Group 5120">
                                                                <path id="Path_5431" data-name="Path 5431"
                                                                    d="M2.3,53.333a2.3,2.3,0,1,0,2.3,2.3A2.3,2.3,0,0,0,2.3,53.333Zm0,3.683a1.381,1.381,0,1,1,1.381-1.381A1.383,1.383,0,0,1,2.3,57.016Z"
                                                                    transform="translate(0 -53.333)" fill="#707070" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div matTooltip="{{ 'control-bar.list-view' | translate }}">
                                                <svg (click)="setViewType('compact')" id="compact-svg" [attr.class]="
                            currentView == 'compact' ? 'active' : ' '
                          " xmlns="http://www.w3.org/2000/svg" width="20.395" height="14.488"
                                                    viewBox="0 0 20.395 14.488">
                                                    <g id="Group_5124" data-name="Group 5124"
                                                        transform="translate(6308 -176)">
                                                        <g id="Group_5111" data-name="Group 5111"
                                                            transform="translate(-6308 176)">
                                                            <g id="Group_5110" data-name="Group 5110">
                                                                <path id="Path_5426" data-name="Path 5426"
                                                                    d="M169.128,96H149.933a.6.6,0,0,0,0,1.2h19.195a.6.6,0,0,0,0-1.2Z"
                                                                    transform="translate(-149.333 -96)"
                                                                    fill="#707070" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_5113" data-name="Group 5113"
                                                            transform="translate(-6308 179.322)">
                                                            <g id="Group_5112" data-name="Group 5112">
                                                                <path id="Path_5427" data-name="Path 5427"
                                                                    d="M169.128,245.333H149.933a.6.6,0,0,0,0,1.2h19.195a.6.6,0,0,0,0-1.2Z"
                                                                    transform="translate(-149.333 -245.333)"
                                                                    fill="#707070" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_5115" data-name="Group 5115"
                                                            transform="translate(-6308 182.644)">
                                                            <g id="Group_5114" data-name="Group 5114">
                                                                <path id="Path_5428" data-name="Path 5428"
                                                                    d="M169.128,394.667H149.933a.6.6,0,0,0,0,1.2h19.195a.6.6,0,0,0,0-1.2Z"
                                                                    transform="translate(-149.333 -394.667)"
                                                                    fill="#707070" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_5122" data-name="Group 5122"
                                                            transform="translate(-6308 185.967)">
                                                            <g id="Group_5112-2" data-name="Group 5112">
                                                                <path id="Path_5427-2" data-name="Path 5427"
                                                                    d="M169.128,245.333H149.933a.6.6,0,0,0,0,1.2h19.195a.6.6,0,0,0,0-1.2Z"
                                                                    transform="translate(-149.333 -245.333)"
                                                                    fill="#707070" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_5123" data-name="Group 5123"
                                                            transform="translate(-6308 189.289)">
                                                            <g id="Group_5114-2" data-name="Group 5114">
                                                                <path id="Path_5428-2" data-name="Path 5428"
                                                                    d="M169.128,394.667H149.933a.6.6,0,0,0,0,1.2h19.195a.6.6,0,0,0,0-1.2Z"
                                                                    transform="translate(-149.333 -394.667)"
                                                                    fill="#707070" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="table-container" [class.rtl]="currLang == 'ar'">
                                    <div [ngStyle]="{
                      'text-align': currLang == 'en' ? 'left' : 'right'
                    }">
                                        <span class="pagination" [ngClass]="
                        currLang == 'en' ? 'float-right' : 'float-left'
                      ">{{ from }} {{ "table-view.to" | translate }} {{ to }}
                                            {{ "table-view.of" | translate }}
                                            {{ total }}
                                            <svg (click)="paginationChange(-1)" [class.disabled]="from == 1" [ngStyle]="{
                          transform:
                            currLang == 'en' ? 'rotate(0)' : 'rotate(180deg)'
                        }" xmlns="http://www.w3.org/2000/svg" width="6.758" height="10.578" viewBox="0 0 6.758 10.578">
                                                <g id="Group_5178" data-name="Group 5178"
                                                    transform="translate(6.053 9.873) rotate(180)">
                                                    <line id="Line_29" data-name="Line 29" x1="5.348" y1="4.584"
                                                        fill="none" stroke="#707070" stroke-linecap="round"
                                                        stroke-width="1" />
                                                    <line id="Line_30" data-name="Line 30" x1="5.348" y2="4.584"
                                                        transform="translate(0 4.584)" fill="none" stroke="#707070"
                                                        stroke-linecap="round" stroke-width="1" />
                                                </g>
                                            </svg>
                                            <svg (click)="paginationChange(1)" [class.disabled]="to == total" [ngStyle]="{
                          transform:
                            currLang == 'en' ? 'rotate(0)' : 'rotate(180deg)'
                        }" xmlns="http://www.w3.org/2000/svg" width="6.758" height="10.578" viewBox="0 0 6.758 10.578">
                                                <g id="Group_5178" data-name="Group 5178"
                                                    transform="translate(0.705 0.705)">
                                                    <line id="Line_29" data-name="Line 29" x1="5.348" y2="4.584"
                                                        transform="translate(0 4.584)" fill="none" stroke="#707070"
                                                        stroke-linecap="round" stroke-width="1" />
                                                    <line id="Line_30" data-name="Line 30" x1="5.348" y1="4.584"
                                                        fill="none" stroke="#707070" stroke-linecap="round"
                                                        stroke-width="1" />
                                                </g>
                                            </svg>
                                        </span>
                                    </div>
                                    <table class="fixed_header">
                                        <thead>
                                            <tr [class]="currentView == 'compact' ? 'compact-tr' : ''">
                                                <th class="check-box--cell" scope="col" [ngStyle]="{
                            'text-align': currLang == 'en' ? 'left' : 'right'
                          }">
                                                    <mat-checkbox [checked]="AllRowsIsChecked"
                                                        (change)="checkBoxSelectAll($event)">
                                                    </mat-checkbox>
                                                </th>
                                                <th class="image" *ngIf="currentView == 'list'" [ngStyle]="{
                            'text-align': currLang == 'en' ? 'left' : 'right'
                          }"></th>
                                                <th [ngStyle]="{
                            'text-align': currLang == 'en' ? 'left' : 'right'
                          }">
                                                    {{ "trip.add-trip-modal.passenger-name" | translate }}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6.686"
                                                        height="11.806" viewBox="0 0 6.686 11.806" [class]="
                                                    order.order_type === 'asc' && order.order_by === 'name'
                                                      ? 'sorting-icons active'
                                                      : 'sorting-icons'
                                                  " (click)="sort('name', 'asc')">
                                                        <path id="Path_5503" data-name="Path 5503"
                                                            d="M3.342,11.806a1.372,1.372,0,0,1-.98-.408L.262,9.285A.9.9,0,0,1,.9,7.748H2.881V.461a.461.461,0,0,1,.922,0V7.748a.923.923,0,0,1-.922.922H.951l2.066,2.078a.459.459,0,0,0,.652,0L5.734,8.67H5.279a.461.461,0,1,1,0-.922h.507a.9.9,0,0,1,.636,1.538L4.322,11.4a1.372,1.372,0,0,1-.98.408Zm0,0"
                                                            transform="translate(0.001)" fill="#707070" />
                                                    </svg>

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6.686"
                                                        height="11.806" viewBox="0 0 6.686 11.806"
                                                        (click)="sort('name', 'desc')" [class]="
                                                    order.order_type === 'desc' && order.order_by === 'name'
                                                      ? 'sorting-icons active'
                                                      : 'sorting-icons'">
                                                        <path id="Path_5502" data-name="Path 5502"
                                                            d="M225.353,11.806a.461.461,0,0,1-.461-.461V4.058h-1.983a.9.9,0,0,1-.636-1.538l2.1-2.113a1.382,1.382,0,0,1,1.96,0l2.1,2.113a.9.9,0,0,1-.636,1.538h-.507a.461.461,0,1,1,0-.922h.454l-2.066-2.078a.459.459,0,0,0-.652,0l-2.066,2.078h1.93a.923.923,0,0,1,.922.922v7.287A.461.461,0,0,1,225.353,11.806Zm0,0"
                                                            transform="translate(-222.01)" fill="#707070" />
                                                    </svg>
                                                </th>
                                                <th [ngStyle]="{
                            'text-align': currLang == 'en' ? 'left' : 'right'
                          }">
                                                    {{
                                                    "trip.add-trip-modal.passenger-phone" | translate
                                                    }}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6.686"
                                                        height="11.806" viewBox="0 0 6.686 11.806" [class]="
                                                      order.order_type === 'asc' && order.order_by === 'phone_no'
                                                        ? 'sorting-icons active'
                                                        : 'sorting-icons'
                                                    " (click)="sort('phone_no', 'asc')">
                                                        <path id="Path_5503" data-name="Path 5503"
                                                            d="M3.342,11.806a1.372,1.372,0,0,1-.98-.408L.262,9.285A.9.9,0,0,1,.9,7.748H2.881V.461a.461.461,0,0,1,.922,0V7.748a.923.923,0,0,1-.922.922H.951l2.066,2.078a.459.459,0,0,0,.652,0L5.734,8.67H5.279a.461.461,0,1,1,0-.922h.507a.9.9,0,0,1,.636,1.538L4.322,11.4a1.372,1.372,0,0,1-.98.408Zm0,0"
                                                            transform="translate(0.001)" fill="#707070" />
                                                    </svg>

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6.686"
                                                        height="11.806" viewBox="0 0 6.686 11.806"
                                                        (click)="sort('phone_no', 'desc')" [class]="
                                                        order.order_type === 'desc' && order.order_by === 'phone_no'
                                                          ? 'sorting-icons active'
                                                          : 'sorting-icons'">
                                                        <path id="Path_5502" data-name="Path 5502"
                                                            d="M225.353,11.806a.461.461,0,0,1-.461-.461V4.058h-1.983a.9.9,0,0,1-.636-1.538l2.1-2.113a1.382,1.382,0,0,1,1.96,0l2.1,2.113a.9.9,0,0,1-.636,1.538h-.507a.461.461,0,1,1,0-.922h.454l-2.066-2.078a.459.459,0,0,0-.652,0l-2.066,2.078h1.93a.923.923,0,0,1,.922.922v7.287A.461.461,0,0,1,225.353,11.806Zm0,0"
                                                            transform="translate(-222.01)" fill="#707070" />
                                                    </svg>
                                                </th>
                                                <th [ngStyle]="{
                            'text-align': currLang == 'en' ? 'left' : 'right'
                          }">
                                                    {{
                                                    "trip.add-trip-modal.passenger-school" | translate
                                                    }}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6.686"
                                                        height="11.806" viewBox="0 0 6.686 11.806" [class]="
                                                      order.order_type === 'asc' && order.order_by === 'school_name'
                                                        ? 'sorting-icons active'
                                                        : 'sorting-icons'
                                                    " (click)="sort('school_name', 'asc')">
                                                        <path id="Path_5503" data-name="Path 5503"
                                                            d="M3.342,11.806a1.372,1.372,0,0,1-.98-.408L.262,9.285A.9.9,0,0,1,.9,7.748H2.881V.461a.461.461,0,0,1,.922,0V7.748a.923.923,0,0,1-.922.922H.951l2.066,2.078a.459.459,0,0,0,.652,0L5.734,8.67H5.279a.461.461,0,1,1,0-.922h.507a.9.9,0,0,1,.636,1.538L4.322,11.4a1.372,1.372,0,0,1-.98.408Zm0,0"
                                                            transform="translate(0.001)" fill="#707070" />
                                                    </svg>

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6.686"
                                                        height="11.806" viewBox="0 0 6.686 11.806"
                                                        (click)="sort('school_name', 'desc')" [class]="
                                                        order.order_type === 'desc' && order.order_by === 'school_name'
                                                          ? 'sorting-icons active'
                                                          : 'sorting-icons'">
                                                        <path id="Path_5502" data-name="Path 5502"
                                                            d="M225.353,11.806a.461.461,0,0,1-.461-.461V4.058h-1.983a.9.9,0,0,1-.636-1.538l2.1-2.113a1.382,1.382,0,0,1,1.96,0l2.1,2.113a.9.9,0,0,1-.636,1.538h-.507a.461.461,0,1,1,0-.922h.454l-2.066-2.078a.459.459,0,0,0-.652,0l-2.066,2.078h1.93a.923.923,0,0,1,.922.922v7.287A.461.461,0,0,1,225.353,11.806Zm0,0"
                                                            transform="translate(-222.01)" fill="#707070" />
                                                    </svg>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-view-body" *ngIf="!loading">
                                            <p *ngIf="!subscribersList.length" class="centered text-muted">
                                                <strong>{{
                                                    currLang === "en"
                                                    ? "No Results Found"
                                                    : "لا توجد بيانات"
                                                    }}</strong>
                                            </p>
                                            <ng-container *ngFor="
                          let subscriber of subscribersList;
                          index as rowIndex
                        ">
                                                <tr [class]="{ 'compact-tr': currentView == 'compact' }">
                                                    <td class="check-box--cell">
                                                        <mat-checkbox [checked]="AllRowsIsChecked" (change)="
                                checkboxAction($event, subscriber, rowIndex)
                              " (click)="$event.stopPropagation()">
                                                        </mat-checkbox>
                                                    </td>
                                                    <td *ngIf="currentView == 'list'" class="image">
                                                        <img class="profile-img"
                                                            [src]="getImage(subscriber.profile_pic)" [ngStyle]="{
                                'text-align':
                                  currLang == 'en' ? 'left' : 'right'
                              }" />
                                                    </td>
                                                    <td [ngStyle]="{
                              'text-align': currLang == 'en' ? 'left' : 'right'
                            }">
                                                        {{ subscriber.name }}
                                                    </td>
                                                    <td [ngStyle]="{
                              'text-align': currLang == 'en' ? 'left' : 'right'
                            }">
                                                        {{ subscriber.phone_no }}
                                                    </td>
                                                    <td [ngStyle]="{
                              'text-align': currLang == 'en' ? 'left' : 'right'
                            }">
                                                        {{ subscriber?.school_name }}
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                        <div *ngIf="loading" class="loading">
                                            <mat-spinner *ngIf="true" mode="indeterminate"></mat-spinner>
                                        </div>
                                    </table>
                                </div> -->


                                <div class="table-wrapper">
                                    <div class='table-container' [class.rtl]="currLang == 'ar'">
                                        <div class="tableFixHead">
                                            <table class="fixed_header">
                                                <thead>
                                                    <tr [class]="currentView == 'compact' ? 'compact-tr' : ''">
                                                        <th class="check-box--cell" scope="col" [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }">
                                                            <mat-checkbox [checked]="AllRowsIsChecked"
                                                                (change)="checkBoxSelectAll($event)">
                                                            </mat-checkbox>
                                                        </th>
                                                        <th class="image" *ngIf="currentView == 'list'" [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }"></th>
                                                        <th [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }">
                                                            {{ "trip.add-trip-modal.passenger-name" | translate }}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="6.686"
                                                                height="11.806" viewBox="0 0 6.686 11.806" [class]="
                                                            order.order_type === 'asc' && order.order_by === 'name'
                                                              ? 'sorting-icons active'
                                                              : 'sorting-icons'
                                                          " (click)="sort('name', 'asc')">
                                                                <path id="Path_5503" data-name="Path 5503"
                                                                    d="M3.342,11.806a1.372,1.372,0,0,1-.98-.408L.262,9.285A.9.9,0,0,1,.9,7.748H2.881V.461a.461.461,0,0,1,.922,0V7.748a.923.923,0,0,1-.922.922H.951l2.066,2.078a.459.459,0,0,0,.652,0L5.734,8.67H5.279a.461.461,0,1,1,0-.922h.507a.9.9,0,0,1,.636,1.538L4.322,11.4a1.372,1.372,0,0,1-.98.408Zm0,0"
                                                                    transform="translate(0.001)" fill="#707070" />
                                                            </svg>
                                                            <!--  -->
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="6.686"
                                                                height="11.806" viewBox="0 0 6.686 11.806"
                                                                (click)="sort('name', 'desc')" [class]="
                                                            order.order_type === 'desc' && order.order_by === 'name'
                                                              ? 'sorting-icons active'
                                                              : 'sorting-icons'">
                                                                <path id="Path_5502" data-name="Path 5502"
                                                                    d="M225.353,11.806a.461.461,0,0,1-.461-.461V4.058h-1.983a.9.9,0,0,1-.636-1.538l2.1-2.113a1.382,1.382,0,0,1,1.96,0l2.1,2.113a.9.9,0,0,1-.636,1.538h-.507a.461.461,0,1,1,0-.922h.454l-2.066-2.078a.459.459,0,0,0-.652,0l-2.066,2.078h1.93a.923.923,0,0,1,.922.922v7.287A.461.461,0,0,1,225.353,11.806Zm0,0"
                                                                    transform="translate(-222.01)" fill="#707070" />
                                                            </svg>
                                                        </th>
                                                        <th [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }">
                                                            {{
                                                            "trip.add-trip-modal.passenger-phone" | translate
                                                            }}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="6.686"
                                                                height="11.806" viewBox="0 0 6.686 11.806" [class]="
                                                              order.order_type === 'asc' && order.order_by === 'phone_no'
                                                                ? 'sorting-icons active'
                                                                : 'sorting-icons'
                                                            " (click)="sort('phone_no', 'asc')">
                                                                <path id="Path_5503" data-name="Path 5503"
                                                                    d="M3.342,11.806a1.372,1.372,0,0,1-.98-.408L.262,9.285A.9.9,0,0,1,.9,7.748H2.881V.461a.461.461,0,0,1,.922,0V7.748a.923.923,0,0,1-.922.922H.951l2.066,2.078a.459.459,0,0,0,.652,0L5.734,8.67H5.279a.461.461,0,1,1,0-.922h.507a.9.9,0,0,1,.636,1.538L4.322,11.4a1.372,1.372,0,0,1-.98.408Zm0,0"
                                                                    transform="translate(0.001)" fill="#707070" />
                                                            </svg>

                                                            <!--  -->
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="6.686"
                                                                height="11.806" viewBox="0 0 6.686 11.806"
                                                                (click)="sort('phone_no', 'desc')" [class]="
                                                                order.order_type === 'desc' && order.order_by === 'phone_no'
                                                                  ? 'sorting-icons active'
                                                                  : 'sorting-icons'">
                                                                <path id="Path_5502" data-name="Path 5502"
                                                                    d="M225.353,11.806a.461.461,0,0,1-.461-.461V4.058h-1.983a.9.9,0,0,1-.636-1.538l2.1-2.113a1.382,1.382,0,0,1,1.96,0l2.1,2.113a.9.9,0,0,1-.636,1.538h-.507a.461.461,0,1,1,0-.922h.454l-2.066-2.078a.459.459,0,0,0-.652,0l-2.066,2.078h1.93a.923.923,0,0,1,.922.922v7.287A.461.461,0,0,1,225.353,11.806Zm0,0"
                                                                    transform="translate(-222.01)" fill="#707070" />
                                                            </svg>
                                                        </th>
                                                        <th [ngStyle]="{
                                    'text-align': currLang == 'en' ? 'left' : 'right'
                                  }">
                                                            {{
                                                            "trip.add-trip-modal.passenger-school" | translate
                                                            }}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="6.686"
                                                                height="11.806" viewBox="0 0 6.686 11.806" [class]="
                                                              order.order_type === 'asc' && order.order_by === 'school_name'
                                                                ? 'sorting-icons active'
                                                                : 'sorting-icons'
                                                            " (click)="sort('school_name', 'asc')">
                                                                <path id="Path_5503" data-name="Path 5503"
                                                                    d="M3.342,11.806a1.372,1.372,0,0,1-.98-.408L.262,9.285A.9.9,0,0,1,.9,7.748H2.881V.461a.461.461,0,0,1,.922,0V7.748a.923.923,0,0,1-.922.922H.951l2.066,2.078a.459.459,0,0,0,.652,0L5.734,8.67H5.279a.461.461,0,1,1,0-.922h.507a.9.9,0,0,1,.636,1.538L4.322,11.4a1.372,1.372,0,0,1-.98.408Zm0,0"
                                                                    transform="translate(0.001)" fill="#707070" />
                                                            </svg>

                                                            <!--  -->
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="6.686"
                                                                height="11.806" viewBox="0 0 6.686 11.806"
                                                                (click)="sort('school_name', 'desc')" [class]="
                                                                order.order_type === 'desc' && order.order_by === 'school_name'
                                                                  ? 'sorting-icons active'
                                                                  : 'sorting-icons'">
                                                                <path id="Path_5502" data-name="Path 5502"
                                                                    d="M225.353,11.806a.461.461,0,0,1-.461-.461V4.058h-1.983a.9.9,0,0,1-.636-1.538l2.1-2.113a1.382,1.382,0,0,1,1.96,0l2.1,2.113a.9.9,0,0,1-.636,1.538h-.507a.461.461,0,1,1,0-.922h.454l-2.066-2.078a.459.459,0,0,0-.652,0l-2.066,2.078h1.93a.923.923,0,0,1,.922.922v7.287A.461.461,0,0,1,225.353,11.806Zm0,0"
                                                                    transform="translate(-222.01)" fill="#707070" />
                                                            </svg>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="table-view-body" *ngIf="!loading">
                                                    <p *ngIf="!subscribersList.length" class="centered text-muted">
                                                        <strong>{{
                                                            currLang === "en"
                                                            ? "No Results Found"
                                                            : "لا توجد بيانات"
                                                            }}</strong>
                                                    </p>
                                                    <ng-container *ngFor="
                                  let subscriber of subscribersList;
                                  index as rowIndex
                                ">
                                                        <tr [class]="{ 'compact-tr': currentView == 'compact' }">
                                                            <td class="check-box--cell">
                                                                <mat-checkbox [checked]="AllRowsIsChecked" (change)="
                                        checkboxAction($event, subscriber, rowIndex)
                                      " (click)="$event.stopPropagation()">
                                                                </mat-checkbox>
                                                            </td>
                                                            <td *ngIf="currentView == 'list'" class="image">
                                                                <img class="profile-img"
                                                                    [src]="getImage(subscriber.profile_pic)" [ngStyle]="{
                                        'text-align':
                                          currLang == 'en' ? 'left' : 'right'
                                      }" />
                                                            </td>
                                                            <td [ngStyle]="{
                                      'text-align': currLang == 'en' ? 'left' : 'right'
                                    }">
                                                                {{ subscriber.name }}
                                                            </td>
                                                            <td [ngStyle]="{
                                      'text-align': currLang == 'en' ? 'left' : 'right'
                                    }">
                                                                {{ subscriber.phone_no }}
                                                            </td>
                                                            <td [ngStyle]="{
                                      'text-align': currLang == 'en' ? 'left' : 'right'
                                    }">
                                                                {{ subscriber?.school_name }}
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                                <div *ngIf="loading" class="loading">
                                                    <mat-spinner *ngIf="true" mode="indeterminate"></mat-spinner>
                                                </div>
                                            </table>
                                        </div>
                                        <div class="table-footer">
                                            <button class="nav-btn prev-btn" (click)="paginationChange(-1)"
                                                [disabled]="from == 1 || subscribersList.length == 0">
                                                <div class="icon">
                                                    <svg [ngStyle]="{'transform': currLang == 'en' ? 'rotate(0)' : 'rotate(180deg)' , 'stroke': from === 1 || subscribersList.length == 0 ? '#B7B7B7' : '#344054'}"
                                                        xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                        viewBox="0 0 20 20" fill="none">
                                                        <path
                                                            d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699"
                                                            stroke-width="1.66667" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                </div>
                                                <div class="label">
                                                    {{'table-view.previous' |translate}}
                                                </div>
                                            </button>
                                            <div class="paganation-text">
                                                {{from | arabicNumber:currLang}} {{'table-view.to'
                                                |translate}} {{to|
                                                arabicNumber:currLang}} {{'table-view.of' | translate}}
                                                {{total | arabicNumber:currLang}}
                                            </div>
                                            <button class="nav-btn next-btn" (click)="paginationChange(1)"
                                                [disabled]="to== total || subscribersList.length == 0">
                                                <div class="label">
                                                    {{'table-view.next' |translate}}
                                                </div>
                                                <div class="icon">
                                                    <svg [ngStyle]="{'transform': currLang == 'en' ? 'rotate(0)' : 'rotate(180deg)', 'stroke': to== total || subscribersList.length == 0 ? '#B7B7B7' : '#344054'}"
                                                        xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                        viewBox="0 0 20 20" fill="none">
                                                        <path
                                                            d="M4.1665 10.0003H15.8332M15.8332 10.0003L9.99984 4.16699M15.8332 10.0003L9.99984 15.8337"
                                                            stroke-width="1.66667" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                </div>
                                            </button>
                                        </div>

                                    </div>
                                </div>




















                            </div>
                            <div class="col-4">
                                <button type="button" class="btn btn-primary save-btn enroll"
                                    (click)="enrollStudents()">
                                    {{ "trip.add-trip-modal.enroll" | translate }}
                                </button>
                                <div class="table-container selected-table" [class.rtl]="currLang == 'ar'">
                                    <table class="fixed_header">
                                        <thead>
                                            <tr [class]="currentView == 'compact' ? 'compact-tr' : ''">
                                                <th [ngStyle]="{
                            'text-align': currLang == 'en' ? 'left' : 'right'
                          }">
                                                    {{
                                                    "trip.add-trip-modal.selected-passengers"
                                                    | translate
                                                    }}
                                                </th>
                                                <th [ngStyle]="{
                            'text-align': currLang == 'en' ? 'right' : 'left'
                          }" (click)="removeStudent(-1)">
                                                    {{ "trip.add-trip-modal.clear-all" | translate }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-view-body" *ngIf="!loading">
                                            <ng-container *ngFor="
                          let subscriber of selectedStudents;
                          index as rowIndex
                        ">
                                                <tr [class]="{ 'compact-tr': currentView == 'compact' }">
                                                    <td class="image">
                                                        <img *ngIf="currentView == 'list'" class="profile-img"
                                                            [src]="getImage(subscriber.profile_pic)" />
                                                    </td>
                                                    <td [ngStyle]="{
                              'text-align': currLang == 'en' ? 'left' : 'right'
                            }">
                                                        {{ subscriber.name }}
                                                    </td>
                                                    <td style="width: 60px">
                                                        <img src="./../../../../../assets/images/deleteRed.svg"
                                                            (click)="removeStudent(rowIndex)" />
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="activeTab == 'passengers'">
                    <div class="row">
                        <div class="col-12" [ngStyle]="{textAlign: currLang == 'en' ? 'right' : 'left'}">
                            <div class="capacity-add-btn-wrapper">
                                <div class="title-capacity">
                                    <div class="title">
                                        {{ "trip.add-trip-modal.stops" | translate }}
                                    </div>
                                    <div class="capacity">
                                        {{ "trip.add-trip-modal.capacity" | translate }} :
                                        <span class="number" [class.gray]="selectedStudents.length == 0">
                                            {{ selectedStudents.length }}/{{ tripData.capacity }}</span>
                                    </div>
                                </div>
                                <button [ngStyle]="{float: currLang == 'en' ? 'left' : 'right'}" type="button"
                                    class="btn btn-primary add-passenger-btn" style="float:right"
                                    (click)="addStudentBtn()" *ngIf="selectedStudents.length > 0 && !fieldsReadOnly">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <path
                                            d="M12 9V15M15 12H9M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
                                            stroke="white" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                    {{ "trip.add-trip-modal.add-passenger" | translate }}
                                </button>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="selectedStudents.length == 0">
                            <img src="./../../../../../assets/images/add-student.svg" alt="" class="image" />
                            <div class="no-data">
                                {{ "trip.add-trip-modal.no-passenger" | translate }}
                            </div>
                            <div class="no-data-desc">
                                {{ "trip.add-trip-modal.no-passenger-desc" | translate }}
                            </div>
                            <button type="button" class="btn btn-primary add-btn" (click)="addStudentBtn()">
                                + {{ "trip.add-trip-modal.add-passenger" | translate }}
                            </button>
                            <br /><br /><br /><br />
                        </div>
                        <ng-container *ngIf="selectedStudents.length > 0">
                            <br /><br /><br />
                            <div class="col-md-7 scroll-container">
                                <ng-container *ngIf="tripData.way == 'to_home'">
                                    <div cdkDropListGroup>
                                        <div class="students-container">
                                            <div cdkDropList [cdkDropListData]="schoolList" class="students-list"
                                                (cdkDropListDropped)="dropSchool($event)">
                                                <div class="student-box" *ngFor="let school of schoolList" cdkDrag
                                                    [cdkDragDisabled]="fieldsReadOnly"
                                                    [ngStyle]="{textAlign: currLang == 'en' ? 'left' : 'right'}">
                                                    <img src="./../../../../../assets/images/drag.svg" class="drag"
                                                        *ngIf="!fieldsReadOnly" />
                                                    <img class="image" [src]="getImage(school.logo)" />
                                                    <div class="name">{{ school.name }}</div>
                                                    <div class="location">{{ school.location }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </ng-container>
                                <div cdkDropListGroup>
                                    <div class="students-container">
                                        <div cdkDropList [cdkDropListData]="selectedStudents" class="students-list"
                                            (cdkDropListDropped)="dropPassenger($event)">
                                            <div class="student-box"
                                                *ngFor="let student of selectedStudents; let in = index" cdkDrag
                                                [cdkDragDisabled]="fieldsReadOnly"
                                                [ngStyle]="{textAlign: currLang == 'en' ? 'left' : 'right'}">
                                                <img src="./../../../../../assets/images/drag.svg" class="drag"
                                                    *ngIf="!fieldsReadOnly" />
                                                <img class="image" [src]="getImage(student?.profile_pic)" />
                                                <div class="name">{{ student?.name }}</div>
                                                <div class="location" style="position: relative;"
                                                    (mouseenter)="student.show_address = true"
                                                    (mouseleave)="student.show_address =false">
                                                    <!-- <input type="text" [(ngModel)]="student.home_location"
                                                        [disabled]="true" /> -->
                                                    <div class="shown-location">
                                                        {{student?.address || student.home_location}}
                                                    </div>

                                                    <!-- Hidden Input Field for ngModel Binding -->
                                                    <input class="hidden-input" type="hidden"
                                                        [(ngModel)]="student.home_location" />
                                                        
                                                    <!-- <div class="adress_hover_wrapper" *ngIf="student?.show_address">
                                                        <div class="address_title">
                                                            {{ "trip.add-trip-modal.hover-address-title" | translate }}
                                                        </div>
                                                        <div class="address" *ngIf="student?.address">
                                                            {{student?.address}}
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <div class="delete">
                                                    <img src="./../../../../../../assets/images/delete.svg"
                                                        (click)="removeStudent(in)" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="tripData.way != 'to_home'">
                                    <hr />
                                    <div cdkDropListGroup>
                                        <div class="students-container">
                                            <div cdkDropList [cdkDropListData]="schoolList" class="students-list"
                                                (cdkDropListDropped)="dropSchool($event)">
                                                <div class="student-box" *ngFor="let school of schoolList" cdkDrag
                                                    [cdkDragDisabled]="fieldsReadOnly"
                                                    [ngStyle]="{textAlign: currLang == 'en' ? 'left' : 'right'}">
                                                    <img src="./../../../../../assets/images/drag.svg" class="drag"
                                                        *ngIf="!fieldsReadOnly" />
                                                    <img class="image" [src]="getImage(school.logo)" />
                                                    <div class="name">{{ school.name }}</div>
                                                    <div class="location">{{ school.location }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="col-md-5">
                                <!-- agm-map -->
                                <google-map #addTripGoogleMap height="400px" width="100%" [zoom]="11" [center]="center"
                                    [options]="options">
                                    <map-directions-renderer
                                        *ngIf="showDirectionOnMap && (directionsResults$ | async) as directionsResults"
                                        [directions]="directionsResults" [options]="renderOptions">
                                    </map-directions-renderer>
                                </google-map>
                                <br />
                                <div style="display:flex; justify-content: center;">
                                    <button type="button" class="map-btn" (click)="drawDirections('default')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14"
                                            viewBox="0 0 15 14" fill="none">
                                            <path
                                                d="M7.50002 10.4999L2.32294 12.093C3.43127 8.28218 5.18212 4.68855 7.50002 1.46702C9.81787 4.68836 11.5687 8.28179 12.6771 12.0924L7.50002 10.4999ZM7.50002 10.4999L7.50002 6.12493"
                                                stroke="#0074EC" stroke-width="1.2" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                        {{ "trip.add-trip-modal.draw" | translate }}
                                    </button>

                                    <button style="margin-inline-start: .5rem;" type="button" class="map-btn"
                                        (click)="drawDirections('optimized')"
                                        [disabled]="tripData.way != 'to_home' && !initialBusLocation">
                                        {{ "trip.add-trip-modal.shortestPath" | translate }}
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <br />
                </ng-container>
            </div>
            <div class="modal-footer" *ngIf="formFlag != 'view'">
                <div class="btns-wrapper">
                    <button type="button" class="btn btn-primary cancel-btn" *ngIf="activeTab != 'add-student'"
                        (click)="closeModalHandler()">
                        {{ "trip.add-trip-modal.cancel" | translate }}
                    </button>
                    <button type="button" class="btn btn-primary save-btn" *ngIf="activeTab == 'info'"
                        (click)="nextBtn()" [disabled]="formTrip.invalid || !isDaySelected">
                        {{ "trip.add-trip-modal.next" | translate }}
                    </button>
                    <button type="button" class="btn btn-primary save-btn" *ngIf="activeTab == 'passengers'"
                        (click)="saveTrip()" [disabled]="saveInProgress || selectedStudents.length == 0">
                        {{ "trip.add-trip-modal.save" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>