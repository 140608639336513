import { Injectable } from '@angular/core';
import { Menu } from '../../models/menu.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
private menu:Menu[]=[
  {name:"side-nav.dashboard",link:"layout/dashboard",img:"../../../../assets/images/svg/dashboardLogo.svg" ,moduleName:"dashboard"},
  {name:"side-nav.management",link:"/layout/management",img:"../../../../assets/images/svg/managementLogo.svg" ,moduleName:"management"}
]

getMenu(){
  return this.menu;
}
constructor() { }

}
