import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private _TripReportDate = new BehaviorSubject<any>(null);
  ReportDate = this._TripReportDate.asObservable();

  setReportDate(date: any) {
    this._TripReportDate.next(date);
  }

  getReportDate() {
    return this._TripReportDate;
  }

  private _LastTripsReportQueryParams = new BehaviorSubject<any>(null);
  LastTripsReportQueryParams = this._LastTripsReportQueryParams;
  setLastTripsReportQueryParams(data: any) {
    this._LastTripsReportQueryParams.next(data);
  }

  getLastTripsReportQueryParams() {
    return this._LastTripsReportQueryParams;
  }
  

  private _LastTripDetailsQueryParams = new BehaviorSubject<any>(null);
  LastTripDetailsQueryParams = this._LastTripDetailsQueryParams;
  setLastTripDetailsQueryParams(data: any) {
    this._LastTripDetailsQueryParams.next(data);
  }

  getLastTripDetailsQueryParams() {
    return this._LastTripDetailsQueryParams;
  }
  
  getRequestHeadersWithPDFContentType() {
    let headers: HttpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'branch-id': `${localStorage.getItem("branch_id")}`,
      'Accept': 'application/xml'
    });
    return headers;
  }
  constructor(private http: HttpClient) { }
  getTripReports(page: number, page_size: number, search_term: string, from_date: any, to_date: any) {
    return this.http.get<any>(
      ` ${environment.url}backoffice/report/getDailyTripFilters?page=${page}&page_size=${page_size}&search=${search_term}${from_date != null ? `&from_date=${from_date}` : ''} ${to_date != null ? `&to_date=${to_date}` : ''}`
    );
  }


  getTripDetailsById(trip_id: number, search_term: string) {
    return this.http.get<any>(
      ` ${environment.url}backoffice/report/getDailyTripPassengersFilters?search=${search_term}&daily_trip_id=${trip_id}`,
    );
  }

  getUserTripHistoryById(id: number, page: number, page_size: number, from_date: any, to_date: any) {
    // id=1662;
    return this.http.get<any>(
      ` ${environment.url}backoffice/report/getDTPForPassenger?subscription_id=${id}&page=${page}&page_size=${page_size}${from_date != null ? `&from_date=${from_date}` : ''} ${to_date != null ? `&to_date=${to_date}` : ''}`,
    );
  }

  exportTripReports(from_date: any, to_date: any, search_term: string) {
    return this.http.get<any>(
      ` ${environment.url}backoffice/report/exportDailyTrips?search=${search_term}${from_date != null ? `&from_date=${from_date}` : ''} ${to_date != null ? `&to_date=${to_date}` : ''}`, { headers: this.getRequestHeadersWithPDFContentType(), responseType: 'blob' as 'json' },
    );
  }

  exportTripDetails(trip_id: number, search_term: string) {
    return this.http.get<any>(
      ` ${environment.url}backoffice/report/exportDailyTripPassengersFilters?search=${search_term}&daily_trip_id=${trip_id}`, { headers: this.getRequestHeadersWithPDFContentType(), responseType: 'blob' as 'json' },
    );
  }

  exportUserTripHistory(id: number, from_date: any, to_date: any) {
    return this.http.get<any>(
      ` ${environment.url}backoffice/report/exportDTPForPassenger?subscription_id=${id}${from_date != null ? `&from_date=${from_date}` : ''} ${to_date != null ? `&to_date=${to_date}` : ''}`, { headers: this.getRequestHeadersWithPDFContentType(), responseType: 'blob' as 'json' },
    );
  }
}
