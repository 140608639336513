import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arabicNumber',
  standalone: true
})
export class ArabicNumberPipe implements PipeTransform {

  transform(value: number | string, currentLanguage: string): string {
    if (currentLanguage === 'ar') {
      if (typeof value === 'string') {
        // If the input is a string, try parsing it to a number
        value = parseFloat(value);
      }

      if (isNaN(value)) {
        // If it's not a valid number, return the original value
        return value?.toString();
      }

      // Convert the number to Arabic numerals
      const arabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];

      return value?.toString()?.replace(/\d/g, digit => arabicNumerals[parseInt(digit, 10)]);
    } else {
      // If the current language is not 'ar', return the original value
      return value?.toString();
    }
  }
}
