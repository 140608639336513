<section
  class="dashboard row container-fluid p-0 m-0"
  [class.rtl]="currLang == 'ar'"
>
  <div class="side-bar col-lg-2 container-fluid p-0 m-0">
    <app-side-nav></app-side-nav>
  </div>
  <div class="dashboard-body col-lg-10 container-fluid p-0">
    <app-header></app-header>
    <router-outlet></router-outlet>
  </div>
</section>
<app-error-msg></app-error-msg>