import { Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Trip } from '../../../../models/trip.model';
import { Area } from '../../../../models/area.model';
import { Bus } from '../../../../models/bus.model';
import { Driver } from '../../../../models/driver.model';
import { Supervisior } from '../../../../models/supervisior.model';
import { Subscriber } from '../../../../models/subscriber.model';
import { Observable, forkJoin, map } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../../shared/services/global.service';
import { AreasService } from '../../../management/_services/areas.service';
import { BusService } from '../../../management/_services/bus.service';
import { DriverService } from '../../../management/_services/driver.service';
import { SupervisiorService } from '../../../management/_services/supervisior.service';
import { SubscriberService } from '../../../management/_services/subscriber.service';
import { StorageService } from '../../../../authentication/services/storage.service';
import { ErrorMsgService } from '../../../../shared/services/error-msg.service';
import { GoogleMapsModule, MapDirectionsService } from '@angular/google-maps';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { TripService } from '../../../management/_services/trip.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorMsgComponent } from '../../../../shared/components/error-msg/error-msg.component';
import { ArabicNumberPipe } from "../../../../shared/pipes/arabic-number.pipe";

@Component({
    selector: 'app-add-trip',
    standalone: true,
    templateUrl: './add-trip.component.html',
    styleUrl: './add-trip.component.scss',
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        GoogleMapsModule,
        MatTooltipModule,
        MatSelectModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        DragDropModule,
        ErrorMsgComponent,
        ArabicNumberPipe
    ]
})
export class AddTripComponent {
  formTrip!: UntypedFormGroup;
  currLang!: string;
  modalTitle!: string;
  formFlag: string = 'add';
  modalTitlFlag:string = 'add'
  loading!: boolean;
  trips: any = [];
  fieldsReadOnly: boolean = false;
  id!: number;
  activeTab = 'info';
  tripData: Trip = {};
  areasList: Area[] = [];
  busesList: Bus[] = [];
  driversList: Driver[] = [];
  supervisorsList: Supervisior[] = [];
  subscribersList: Subscriber[] = [];
  weekDays: any[] = [];
  isDaySelected: boolean = false;
  selectedStudents: any[] = [];
  oldSelectedStudents: any[] = [];
  schoolList: any[] = [];
  deletedStudents: any[] = [];

  lat: number = 0;
  lng: number = 0;
  waypoints: any[] = [];
  origin: any = { lat: 0, lng: 0 };
  destination: any = { lat: 0, lng: 0 };
  saveInProgress: boolean = false;
  public markerOptions = {
    origin: {
      icon: './../../../../../assets/images/school-point.svg',
    },
    destination: {
      icon: './../../../../../assets/images/map-point.svg',
      // infoWindow: `
      //   <h4>Hello<h4>
      //   <a href='http://www-e.ntust.edu.tw/home.php' target='_blank'>Taiwan Tech</a>
      //   `
    },
  };
  public renderOptions = {
    suppressMarkers: false,
  };

  searchText: string = '';
  pageNumber: number = 1;
  itemsPerPage: number = 25;
  AllRowsIsChecked = false;
  currentView: string = 'list';
  from: number = 0;
  to: number = 0;
  total: number = 0;
  timer: any = null;
  dublicateFlag = false;
  order: { order_type?: string; order_by?: string } = {};
  @ViewChild('modal') modal!: ElementRef;

  @HostListener('click', ['$event.target']) onClick(event: any) {
    const parent = this.modal.nativeElement;
    if (!parent.contains(event)) {
      // this.closeModalHandler();
    }
  }
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: true,
    disableDefaultUI: true,
    fullscreenControl: true,
    disableDoubleClickZoom: true,
    mapTypeControl: true,
    scrollwheel: true
    // mapTypeId: 'hybrid',
    // maxZoom:this.maxZoom,
    // minZoom:this.minZoom,
  };
  directionsResults$!: Observable<google.maps.DirectionsResult | undefined>;
  showDirectionOnMap: boolean = false;
  studentsPointForGoTrip: any = []
  initialBusLocation: any = null;
  constructor(
    private TripService: TripService,
    private translate: TranslateService,
    private globalService: GlobalService,
    private areaService: AreasService,
    private busService: BusService,
    private driverService: DriverService,
    private supervisorService: SupervisiorService,
    private subscriberService: SubscriberService,
    private storage: StorageService,
    private errorMsgService: ErrorMsgService,
    private mapDirectionsService: MapDirectionsService,
    private renderer: Renderer2
  ) {
    this.globalService.getLang().subscribe((lng) => {
      this.currLang = lng ? lng : "en";
      this.translate.use(this.currLang);
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currLang = res.lang ? res.lang : "en";
      this.translate.use(this.currLang);
    });
  }

  ngOnInit(): void {

    this.waypoints = [
      // { location: { lat: 29.144428241488328, lng: 31.101263390665274 } },
      // { location: { lat: 30.380408711132553, lng: 30.527458583916843 } }
    ];

    this.weekDays = [
      {
        name: 'Saturday',
        isSelected: false,
      },
      {
        name: 'Sunday',
        isSelected: false,
      },
      {
        name: 'Monday',
        isSelected: false,
      },
      {
        name: 'Tuesday',
        isSelected: false,
      },
      {
        name: 'Wednesday',
        isSelected: false,
      },
      {
        name: 'Thursday',
        isSelected: false,
      },
      {
        name: 'Friday',
        isSelected: false,
      },
    ];

    this.formTrip = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      way: new UntypedFormControl('to_school', Validators.required),
      start_time: new UntypedFormControl('', Validators.required),
      end_time: new UntypedFormControl('', Validators.required),
      waiting_time: new UntypedFormControl(0, Validators.required),
      capacity: new UntypedFormControl(''),
      start_date: new UntypedFormControl(''),
      end_date: new UntypedFormControl(''),
      driver_id: new UntypedFormControl('', Validators.required),
      area_id: new UntypedFormControl('', Validators.required),
      bus_id: new UntypedFormControl('', Validators.required),
      supervisor_id: new UntypedFormControl(null),
    });
    this.TripService.getTripData().subscribe((res:any) => {
      if (res?.trip != null) {
        this.formFlag = 'view';
        this.modalTitlFlag = 'view';
        this.fieldsReadOnly = true;
        this.id = res.trip.id;
        this.tripData = res.trip;

        this.formTrip.setValue({
          name: this.tripData.name,
          way: this.tripData.way,
          start_time: this.tripData.start_time,
          end_time: this.tripData.end_time,
          waiting_time: this.tripData.waiting_time,
          capacity: this.tripData.capacity,
          start_date: this.tripData.start_date,
          end_date: this.tripData.end_date,
          driver_id: this.tripData.driver_id,
          area_id: this.tripData.area_id,
          bus_id: this.tripData.bus_id,
          supervisor_id: this.tripData.supervisor_id,
        });
        this.weekDays.forEach((e) => {
          e.isSelected = this.tripData.days?.includes(e.name);
        });
        this.isDaySelected = this.tripData.days?.length ? true : false;
        this.selectedStudents = this.tripData.trip_passengers!.map((e) => {
          return e.subscription ? Object.assign(e.subscription, { trip_passenger_id: e.id }) : e;
          // return Object.assign(e.subscription, { trip_passenger_id: e.id })
        });
        // to select the first location of passesengers as bus departure point by default on init of modal
        this.initialBusLocation = this.selectedStudents[0].home_location;
      }
    });
    this.getData();
  }
  getData() {
    this.loading = true;
    forkJoin([
      this.areaService.getAreas(1, 99999, [], {}, {}),
      this.busService.getBusess(1, 99999, [], [], {}, {}),
      this.driverService.getDrivers(1, 99999, [], {}, {}),
      this.supervisorService.getSupervisiors(1, 99999, [], {}, {}),
    ]).subscribe(
      (Results) => {
        this.areasList = Results[0].data.areas.data.map((e: Area) => {
          return {
            id: e.id,
            name: e.name,
          };
        });
        this.busesList = Results[1].data.buses.data.map((e: Bus) => {
          return {
            id: e.id,
            name: e.name,
            capacity: e.capacity,
          };
        });
        this.driversList = Results[2].data.drivers.data.map((e: Area) => {
          return {
            id: e.id,
            name: e.name,
          };
        });
        this.supervisorsList = Results[3].data.supervisors.data.map(
          (e: Supervisior) => {
            return {
              id: e.id,
              name: e.name,
            };
          }
        );
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.errorMsgService.setMessage(error.error.Error[0]);
      }
    );
  }
  getSubscribersData() {
    this.loading = true;

    const excluded_ids = this.selectedStudents.map(el => el.id)
    this.subscriberService
      .getSearchSubscribers(this.pageNumber, this.itemsPerPage, [], this.searchText, excluded_ids, this.order)
      .subscribe(
        (res) => {
          this.subscribersList = res.data.subscriptions.data;
          // this.selectedStudents = this.subscribersList.slice(0, 50);
          this.from = res.data.subscriptions['from'];
          this.to = res.data.subscriptions['to'];
          this.total = res.data.subscriptions['total'];
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.errorMsgService.setMessage(error.error.Error[0]);
        }
      );
  }

  addStudentBtn() {
    // debugger;
    this.activeTab = 'add-student';
    this.oldSelectedStudents = [...this.selectedStudents];
    this.getSubscribersData();
  }
  sort(order_by: any, order_type: any) {
    this.order = {
      order_by, order_type
    }
    this.getSubscribersData()
    // this.eventHandeler.emit({
    //   action: 'sorting',
    //   target: {
    //     source: col.source,
    //     order: order,
    //   },
    // });
  }
  closeModalHandler() {
    this.formFlag = 'add';
    this.modalTitlFlag = 'add';
    this.activeTab = 'info';
    this.fieldsReadOnly = false;
    this.formTrip.reset();
    this.selectedStudents = [];
    this.oldSelectedStudents = [];
    this.schoolList = [];
    this.deletedStudents = [];
    this.weekDays.forEach((element) => {
      element.isSelected = false;
    });
    this.isDaySelected = false;
    this.searchText = '';
    this.formTrip.controls['way'].setValue('to_school');
    this.formTrip.controls['waiting_time'].setValue(0);
    //reset map
    this.lat = 0;
    this.lng = 0;
    this.center = {
      lat: this.lat,
      lng: this.lng
    };
    this.waypoints = [];
    this.origin = { lat: 0, lng: 0 };
    this.destination = { lat: 0, lng: 0 };
    this.dublicateFlag = false;
    this.order = {}
    this.showDirectionOnMap = false;
    this.initialBusLocation = null;
    this.saveInProgress = false;
    this.closeModal();
  }

  closeModal() {
    const modal = this.renderer.selectRootElement('#addTripModal', true);
    this.renderer.setStyle(modal, 'display', 'none');
    this.renderer.removeClass(modal, 'show');
  }
  getValidationMessage(control: any) {
    let errorMsg;
    if (this.formTrip.controls[control].errors) {
      const fieldName = this.translate.instant(
        'trip.add-trip-modal.' + control
      );
      const requiredText = this.translate.instant(
        'trip.add-trip-modal.required'
      );
      const invalidText = this.translate.instant('trip.add-trip-modal.invalid');
      errorMsg = this.formTrip.controls[control].errors?.['required']
        ? `${fieldName + requiredText}`
        : `${fieldName + invalidText}`;
    }
    return errorMsg;
  }

  editTrip() {
    this.formFlag = 'edit';
    this.modalTitlFlag = 'edit';
    this.fieldsReadOnly = false;
  }

  daySelected(index: number) {
    this.weekDays[index].isSelected = !this.weekDays[index].isSelected;
    let days = this.weekDays.filter((e) => e.isSelected);
    this.isDaySelected = days.length ? true : false;
  }
  nextBtn() {
    let selectedBus = this.busesList.find(
      (e) => e.id == this.formTrip.value.bus_id
    );
    this.tripData.capacity = selectedBus?.capacity;

    this.getSchoolList();
    this.activeTab = 'passengers';
  }
  getSchoolList() {
    this.schoolList = this.selectedStudents.map((e) => {
      return {
        location: e.school_location,
        logo: e.school_logo,
        name: e.school_name,
      };
    });

    this.schoolList = this.schoolList.filter(function (elem, index, self) {
      return index === self.findIndex((e: any) => elem.location == e.location);
    });

    // this.drawDirections();
  }
  drawDirections(type: string) {
    let studentsMapPoints = this.selectedStudents.filter(function (
      elem,
      index,
      self
    ) {
      return (
        index ===
        self.findIndex((e: any) => elem.home_location == e.home_location)
      );
    });

    if (this.tripData.way == 'to_home') {
      this.origin.lat = +this.schoolList[0].location.substring(
        0,
        this.schoolList[0].location.indexOf(',')
      );
      this.origin.lng = +this.schoolList[0].location.substring(
        this.schoolList[0].location.indexOf(',') + 1
      );

      this.lat = +this.origin.lat;
      this.lng = +this.origin.lng;
      this.center = {
        lat: this.lat,
        lng: this.lng
      };
      this.destination.lat = +studentsMapPoints[
        studentsMapPoints.length - 1
      ].home_location.substring(
        0,
        studentsMapPoints[studentsMapPoints.length - 1].home_location.indexOf(
          ','
        )
      );
      this.destination.lng = +studentsMapPoints[
        studentsMapPoints.length - 1
      ].home_location.substring(
        studentsMapPoints[studentsMapPoints.length - 1].home_location.indexOf(
          ','
        ) + 1
      );

      let schoolWaypoints = this.schoolList.map((e) => {
        return {
          location: {
            lat: +e.location.substring(0, e.location.indexOf(',')),
            lng: +e.location.substring(e.location.indexOf(',') + 1),
          },
        };
      });
      let passengersWaypoints = this.selectedStudents.map((e) => {
        return {
          location: {
            lat: +e.home_location.substring(0, e.home_location.indexOf(',')),
            lng: +e.home_location.substring(e.home_location.indexOf(',') + 1),
          },
        };
      });
      passengersWaypoints.splice(passengersWaypoints.length - 1, 1);
      schoolWaypoints.splice(0, 1);

      this.waypoints = [...schoolWaypoints, ...passengersWaypoints];
      this.toggleDirectionAndShortestPath(type, 'to_home')
    } else {
      this.origin.lat = +studentsMapPoints[0].home_location.substring(
        0,
        studentsMapPoints[0].home_location.indexOf(',')
      );
      this.origin.lng = +studentsMapPoints[0].home_location.substring(
        studentsMapPoints[0].home_location.indexOf(',') + 1
      );

      this.lat = +this.origin.lat;
      this.lng = +this.origin.lng;
      this.center = {
        lat: this.lat,
        lng: this.lng
      };
      this.destination.lat = +this.schoolList[
        this.schoolList.length - 1
      ].location.substring(
        0,
        this.schoolList[this.schoolList.length - 1].location.indexOf(',')
      );
      this.destination.lng = +this.schoolList[
        this.schoolList.length - 1
      ].location.substring(
        this.schoolList[this.schoolList.length - 1].location.indexOf(',') + 1
      );

      let schoolWaypoints = this.schoolList.map((e) => {
        return {
          location: {
            lat: +e.location.substring(0, e.location.indexOf(',')),
            lng: +e.location.substring(e.location.indexOf(',') + 1),
          },
        };
      });
      let passengersWaypoints = studentsMapPoints.map((e) => {
        return {
          location: {
            lat: +e.home_location.substring(0, e.home_location.indexOf(',')),
            lng: +e.home_location.substring(e.home_location.indexOf(',') + 1),
          },
        };
      });
      schoolWaypoints.splice(schoolWaypoints.length - 1, 1);
      passengersWaypoints.splice(0, 1);

      this.waypoints = [...passengersWaypoints, ...schoolWaypoints];
      this.studentsPointForGoTrip = [];
      this.studentsPointForGoTrip = studentsMapPoints;
      this.toggleDirectionAndShortestPath(type, 'to_school', studentsMapPoints)
    }
    // let optimiziedWaypoints:any = [];
    // if(this.waypoints.length > 0 ){
    //   optimiziedWaypoints = this.waypoints.map((obj) => {
    //     return {
    //       'location':new google.maps.LatLng(obj.location.lat,obj.location.lng),
    //       'stopover':true
    //     }
    //   });
    // }
    // if(type == "optimized"){
    //   this.optimizeShortestPath(this.origin,this.destination)
    // }
    // else{
    //   this.getDirection(this.origin,this.destination,optimiziedWaypoints ,null , type);
    // }
    // this.showDirectionOnMap = true;
  }
  wayChanged() {
    this.tripData.way = this.formTrip.value.way;
  }
  dropPassenger(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    // to select the first location of passesengers as bus departure point every time passengers order is changed.
    this.initialBusLocation = this.selectedStudents[0].home_location;
  }
  dropSchool(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );

  }
  getImage(imagePath: string) {
    return this.storage.imageUrl + imagePath;
  }
  removeStudent(index: number) {
    // debugger;
    if (index == -1) {
      this.deletedStudents = [
        ...this.deletedStudents,
        ...this.selectedStudents
          .filter((e) => e.trip_passenger_id)
          .map((e) => e.trip_passenger_id),
      ];
      this.subscribersList = [...this.subscribersList, ...this.selectedStudents]
      this.selectedStudents = [];
    } else {
      this.subscribersList.push(this.selectedStudents[index]);
      this.subscribersList = [...new Map(this.subscribersList.map(item => [item['name'], item])).values()]
      if (this.selectedStudents[index].trip_passenger_id) {
        this.deletedStudents.push(
          this.selectedStudents[index].trip_passenger_id
        );
      }
      this.selectedStudents.splice(index, 1);
    }
    this.deletedStudents = [...new Set(this.deletedStudents)]
    // to select the first location of passesengers as bus departure point every time passengers order is changed.
    this.initialBusLocation = this.selectedStudents[0].home_location;
  }
  saveTrip() {
    this.saveInProgress = true;
    let body = this.formTrip.value;
    body.days = [];
    this.weekDays.forEach((e) => {
      if (e.isSelected) {
        body.days.push(e.name);
      }
    });
    body.capacity = this.tripData.capacity;
    body['trip_passengers'] = this.selectedStudents.map((e, index) => {
      let object: any = {
        subscription_id: e.id,
        source_location: body.way == 'to_school' ? e.home_location : null,
        destination_location: body.way == 'to_home' ? e.home_location : null,
        pick_order:
          body.way == 'to_school'
            ? index + 1
            : this.schoolList.findIndex(
              (s) => s.location == e.school_location
            ) + 1,
        drop_order:
          body.way == 'to_home'
            ? index + 1
            : this.schoolList.findIndex(
              (s) => s.location == e.school_location
            ) + 1,
        trip_passenger_id: e.trip_passenger_id,
      };
      if (body.way == 'to_school') {
        delete object.destination_location;
      } else {
        delete object.source_location;
      }
      return object;
    });
    if (this.dublicateFlag) {
      // delete trip id and passengers ids in case of dublication.
      delete body.trip_id;
      if (body.trip_passengers) {
        body.trip_passengers.forEach((passenger: any) => {
          passenger.trip_passenger_id ? delete passenger.trip_passenger_id : '';
        });
      }
    }
    if (this.formFlag == 'add' || this.dublicateFlag == true) {
      this.TripService.createTrip(body).subscribe(
        () => {
          this.TripService.setLoadTripsData(true);
          this.closeModalHandler()
        },
        (error:any) => {
          this.errorMsgService.setMessage(error.error.Error[0]);
          this.activeTab = 'info';
          this.saveInProgress = false;
        }
      );
    } else {
      body.trip_id = this.id;
      body.deleted_trip_passengers = this.deletedStudents;
      this.TripService.updateTrip(body).subscribe(
        () => {
          this.TripService.setLoadTripsData(true);
          this.closeModalHandler()

        },
        (error:any) => {
          error.error.Error[0] == "Invalid driver id" ? this.errorMsgService.setMessage(this.translate.instant('trip.add-trip-modal.driver-deleted')) :
            this.errorMsgService.setMessage(error.error.Error[0]);
          this.activeTab = 'info';
          this.saveInProgress = false;
        }
      );
    }
  }

  checkBoxSelectAll(event: any) {
    // debugger;
    this.oldSelectedStudents = [...this.selectedStudents]
    this.AllRowsIsChecked = event.checked;
    if (this.AllRowsIsChecked) {

      this.selectedStudents = [

        ...this.oldSelectedStudents,
        ...this.subscribersList,
      ];
      this.subscribersList = [];
      this.getSubscribersData();
      setTimeout(() => {
        this.AllRowsIsChecked = false;
      }, 500);
    } else {
      this.selectedStudents = [...this.oldSelectedStudents];
    }
  }

  checkboxAction(event: any, row: any, index: any) {
    if (event.checked) {
      this.selectedStudents.push(this.subscribersList[index]);
      this.selectedStudents = [...new Map(this.selectedStudents.map(item => [item['name'], item])).values()]

      this.subscribersList.splice(index, 1);
      let deletedIndex = this.deletedStudents.findIndex(el => el == row.trip_passenger_id)

      if (deletedIndex != -1) {
        this.deletedStudents.splice(deletedIndex, 1)
      }

    } else {
      this.AllRowsIsChecked = false;
      let passengerIndex = this.selectedStudents.findIndex(
        (e) => e.id == row.id
      );
      if (passengerIndex != -1) {
        this.selectedStudents.splice(passengerIndex, 1);
      }
    }
  }
  paginationChange(direction: any) {
    if (
      (direction == 1 && this.to != this.total) ||
      (direction == -1 && this.from != 1)
    ) {
      this.pageNumber += direction;
      this.oldSelectedStudents = [...this.selectedStudents];
      this.getSubscribersData();
    }
  }
  enrollStudents() {
    this.getSchoolList();
    if (!this.tripData.id) {
      this.selectedStudents = this.selectedStudents.sort((a, b) => {
        if (a.home_location > b.home_location) {
          return 1;
        } else if (a.home_location < b.home_location) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    this.activeTab = 'passengers';
    this.searchText = '';
    this.order = {}
    // to select the first location of passesengers as bus departure point after passenger enrollement
    this.initialBusLocation = this.selectedStudents[0].home_location;
  }

  setViewType(type: string) {
    this.currentView = type;
  }
  onKeyup($event: any) {
    let term = $event.target.value.trim();
    const regex = '^[a-zA-z0-9-_\u0621-\u064A\u0660-\u0669 ]+$';
    let foundError = term.match(regex);
    if (term === '' || foundError !== null) {
      // the search input is empty or doesnt has special chars
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.searchText = term;
        this.pageNumber = 1;
        this.getSubscribersData();
      }, 1400);
    } else {
      this.errorMsgService.setMessage(
        this.translate.instant('control-bar.special-warning')
      );
    }
  }
  dublicateTrip() {
    this.dublicateFlag = true;
    this.formFlag = 'edit';
    this.modalTitlFlag = 'dublicate'
    this.fieldsReadOnly = false;

  }
  ngOnDestroy() {
    this.TripService.setTripData(null, 'add');
    this.deletedStudents = [];
  }

  getDirection(origin: any, destination: any, waypoints: any, type: any, maxIndex?: any) {
    const request: google.maps.DirectionsRequest = {
      destination: destination,
      origin: origin,
      waypoints: waypoints,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING
    };
    this.directionsResults$ = this.mapDirectionsService.route(request).pipe(map(response => response.result));
    if (type == 'optimized') {
      this.directionsResults$.subscribe(response => this.reArrangeSelectedStudents(maxIndex, response?.routes[0].waypoint_order));
    }
  }
  toggleDirectionAndShortestPath(type: any, trip_way: any, studentMapPoints?: any) {
    // debugger
    let optimiziedWaypoints: any = [];
    let mapedStudentMapPoints: any = []
    if (this.waypoints.length > 0) {
      optimiziedWaypoints = this.waypoints.map((obj) => {
        return {
          'location': new google.maps.LatLng(obj.location.lat, obj.location.lng),
          'stopover': true
        }
      });
    }
    if (!!studentMapPoints && studentMapPoints.length > 0) {
      mapedStudentMapPoints = studentMapPoints.map((e: any) => {
        return {
          location: {
            lat: +e.home_location.substring(0, e.home_location.indexOf(',')),
            lng: +e.home_location.substring(e.home_location.indexOf(',') + 1),
          },
        };
      });
    }

    if (type == "optimized") {
      if (trip_way == 'to_home') {
        this.optimizeShortestPath(this.origin, this.destination)
      }
      else {
        let optimizedStudentMapPoints = mapedStudentMapPoints.map((obj: any) => {
          return new google.maps.LatLng(obj.location.lat, obj.location.lng)
        });
        this.preSetOriginDestinationForOptimizationGoTrip(optimizedStudentMapPoints)
      }
    }
    else {
      this.getDirection(this.origin, this.destination, optimiziedWaypoints, null, type);
    }
    this.showDirectionOnMap = true;
  }
  preSetOriginDestinationForOptimizationGoTrip(studentMapPoints: any) {
    let optimizationOrigin: any = {};

    optimizationOrigin.lat = +this.initialBusLocation.substring(
      0,
      this.initialBusLocation.indexOf(',')
    );
    optimizationOrigin.lng = +this.initialBusLocation.substring(
      this.initialBusLocation.indexOf(',') + 1
    );
    this.optimizeShortestPath(optimizationOrigin, studentMapPoints)
  }
  optimizeShortestPath(origin: any, destination: any) {
    const service = new google.maps.DistanceMatrixService();
    // finding the latlng object of origin , destination and waypoints for distance matrix api
    let latlngDestination = this.tripData.way == 'to_home' ? new google.maps.LatLng(destination.lat, destination.lng) : destination;
    let latlngOrigin = new google.maps.LatLng(origin.lat, origin.lng)
    let latlngWaypoints = this.waypoints.map((obj) => {
      return new google.maps.LatLng(obj.location.lat, obj.location.lng)
    });
    // destinations array contains all points except origin , to use this array in distance matrix api to find the distance between every point and origin
    let destinations = this.tripData.way == 'to_home' ? [...latlngWaypoints, latlngDestination] : destination;
    // creating the distance matrix object to send in the request to distance matrix api , it takes origins , destinations and travel mode as required parameters
    let distanceMatrixReqObj: any = {}
    distanceMatrixReqObj.origins = [latlngOrigin];
    distanceMatrixReqObj.destinations = destinations;
    distanceMatrixReqObj.travelMode = "DRIVING";
    distanceMatrixReqObj.unitSystem = 0;
    distanceMatrixReqObj.avoidHighways = false;
    distanceMatrixReqObj.avoidTolls = false;
    // the call to the distance matrix api
    service.getDistanceMatrix(distanceMatrixReqObj).then((response) => {
      // this method to find the index of the fathest point from origin
      const maxIndex = this.findIndexOfMaxDistance(response.rows[0].elements);
      // now i need to get the farthest point from the destinations array using the maxIndex i have , this will be my new destination to get direction
      const farthestDestination = destinations[maxIndex];
      // now i need to create a new waypoints array without the destination point 
      let wayPointsWithoutDestination = this.tripData.way == 'to_home' ? destinations.filter((destination: any) => destination != farthestDestination) : destinations;

      // this map just to organize the structure of the waypoints to meet the requirements of the Directions api , to get the new optimized directions
      let optimizeWaypoints = wayPointsWithoutDestination.map((obj: any) => {
        return {
          'location': obj,
          'stopover': true
        }
      });
      if (this.tripData.way == 'to_home') {
        this.getDirection(origin, farthestDestination, optimizeWaypoints, 'optimized', maxIndex)
      }
      else {
        let optimizedLastPoint: any = {}
        optimizedLastPoint.lat = +this.schoolList[0].location.substring(
          0,
          this.schoolList[0].location.indexOf(',')
        );
        optimizedLastPoint.lng = +this.schoolList[0].location.substring(
          this.schoolList[0].location.indexOf(',') + 1
        );
        this.getDirection(origin, optimizedLastPoint, optimizeWaypoints, 'optimized', maxIndex)
      }
    })
  }

  optimizeShortestPathForGoTrip(madrsa: any, ba2yelno2t: any) {
    //awl 7aga h7sb ab3d student 3n el madrsa 3shan yb2a el source bta3y 

    const service = new google.maps.DistanceMatrixService();
    // finding the latlng object of origin , destination and waypoints for distance matrix api
    let latlngDestination = ba2yelno2t;
    let latlngOrigin = new google.maps.LatLng(madrsa.lat, madrsa.lng)
    let latlngWaypoints = this.waypoints.map((obj) => {
      return new google.maps.LatLng(obj.location.lat, obj.location.lng)
    });
    // destinations array contains all points except origin , to use this array in distance matrix api to find the distance between every point and origin
    let destinations = latlngDestination;
    // creating the distance matrix object to send in the request to distance matrix api , it takes origins , destinations and travel mode as required parameters
    let distanceMatrixReqObj: any = {}
    distanceMatrixReqObj.origins = [latlngOrigin];
    distanceMatrixReqObj.destinations = destinations;
    distanceMatrixReqObj.travelMode = "DRIVING";
    distanceMatrixReqObj.unitSystem = 0;
    distanceMatrixReqObj.avoidHighways = false;
    distanceMatrixReqObj.avoidTolls = false;
    // the call to the distance matrix api
    service.getDistanceMatrix(distanceMatrixReqObj).then((response) => {
      console.log(response);
      // this method to find the index of the fathest point from origin
      const maxIndex = this.findIndexOfMaxDistance(response.rows[0].elements);
      // now i need to get the farthest point from the destinations array using the maxIndex i have , this will be my new destination to get direction
      const farthestDestination = destinations[maxIndex];

    })
  }

  findIndexOfMaxDistance(arr: any) {
    return arr.reduce((maxIndex: any, elem: any, i: any, arr: any) =>
      elem.distance.value > arr[maxIndex].distance.value ? i : maxIndex, 0);
  }

  reArrangeSelectedStudents(maxIndex: any, orderArray: any) {
    // console.log(orderArray);
    // console.log(this.selectedStudents);
    // create new array of students and remove the farthest student without changing the original selectedStudents array hence the use of slice not splice
    const studentsWithoutFarthestStudent = this.selectedStudents.slice(0, maxIndex).concat(this.selectedStudents.slice(maxIndex + 1));
    // Store the remove farthest student in a variable
    const farthestStudent = this.selectedStudents[maxIndex];
    // console.log(studentsWithoutFarthestStudent);
    // console.log(farthestStudent);
    // compare callback function for the sorthing the students without farthest student array by the order array from direction api optimization
    const customSort = (a: any, b: any) => {
      return orderArray.indexOf(studentsWithoutFarthestStudent.indexOf(a)) - orderArray.indexOf(studentsWithoutFarthestStudent.indexOf(b));
    };
    // new sorted array from the students without farthes student array 
    const sortedstudentsWithoutFarthestStudent = studentsWithoutFarthestStudent.slice().sort(customSort);
    // now pushing the farthest student to the sorted array
    sortedstudentsWithoutFarthestStudent.push(farthestStudent);
    // console.log(sortedstudentsWithoutFarthestStudent);
    // this.selectedStudents = []
    this.selectedStudents = sortedstudentsWithoutFarthestStudent;
  }

  
}
